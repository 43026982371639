import { MouseEvent, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';
import {
  Button,
  ButtonProps,
  EmphasizeButton,
  Icon,
  ResponsiveDropdown,
  TipButton,
} from '@front/ui';

const styles = {
  button: {
    width: '100%',
    minWidth: 0,
    flex: 1,
    px: 'unset',
  },
  buttonSm: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },
  dropdownPopper: (width = 152) => ({
    zIndex: 10,
    '& .popper-content': {
      minWidth: `${width}px`,
    },
  }),
  option: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
};

type ButtonDropdownOption = {
  display: string;
  iconName: string;
  onClick: () => void;
};

type RenderOptionProps = {
  display: string;
  iconName: string;
};

const RenderOption = ({ display, iconName }: RenderOptionProps) => {
  return (
    <Box sx={styles.option}>
      <Icon name={iconName} size={16} />
      {display}
    </Box>
  );
};

type ButtonDropdownProps = {
  open: boolean;
  anchorEl?: HTMLElement | null;
  options?: ButtonDropdownOption[];
  onClick: (option: ButtonDropdownOption) => void;
  onClose: () => void;
};

function ButtonDropdown({
  open,
  anchorEl,
  options = [],
  onClick,
  onClose,
}: ButtonDropdownProps) {
  return (
    <ResponsiveDropdown
      open={open}
      options={options}
      onClick={onClick}
      onClose={onClose}
      menuDropdownProps={{
        anchorEl: anchorEl,
        popperProps: {
          sx: styles.dropdownPopper((anchorEl?.clientWidth ?? 148) + 4),
          placement: 'bottom-end',
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ],
          },
        },
      }}
      renderOption={(ev) => (
        <RenderOption display={ev.display} iconName={ev.iconName} />
      )}
    />
  );
}

export type ProfileButtonProps = {
  icon?: string;
  label?: string;
  dropdownOptions?: ButtonDropdownOption[];
  buttonType: 'iconButton' | 'emphasizeButton' | 'button';
  size?: 'sm' | 'md';
  loading?: boolean;
  variant?: ButtonProps['variant'];
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export default function ProfileButton({
  icon,
  label,
  dropdownOptions = [],
  buttonType,
  variant = 'outlined',
  loading = false,
  size = 'md',
  onClick,
  onMouseDown,
}: ProfileButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const handleDropdownClick = (option: ButtonDropdownOption) => {
    setDropdownOpen(false);
    option.onClick();
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (dropdownOptions.length > 0) {
      setDropdownOpen(!dropdownOpen);
      return;
    }
    onClick?.(e);
  };

  if (buttonType === 'iconButton') {
    return (
      <TipButton
        customSize={24}
        loading={loading}
        onClick={onClick}
        onMouseDown={onMouseDown}
        title={label || ''}
      >
        {icon && <Icon name={icon} width={16} height={16} />}
      </TipButton>
    );
  }

  const ButtonComponent = buttonType === 'button' ? Button : EmphasizeButton;
  return (
    <>
      <ButtonComponent
        ref={buttonRef}
        prefixIcon={icon && <Icon name={icon} width={16} height={16} />}
        suffixIcon={dropdownOptions.length > 0 && <ActionChevronDownIcon />}
        onClick={handleClick}
        onMouseDown={onMouseDown}
        variant={variant}
        loading={loading}
        sx={[styles.button, size === 'sm' && styles.buttonSm]}
      >
        {label}
      </ButtonComponent>
      <ButtonDropdown
        open={dropdownOpen}
        anchorEl={buttonRef.current}
        onClose={handleCloseDropdown}
        onClick={handleDropdownClick}
        options={dropdownOptions}
      />
    </>
  );
}
