import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { TableLayoutConfig } from '@lib/ia/src/layouts/TableLayout/types';
import { TFunction } from 'i18next';

const getTabColor = (tab: keyof GetAnalyticsWeaknessTopicRes) => {
  if (tab === 'mistake') return 'error';
  if (tab === 'overtime') return 'warning';
  return 'primary';
};
const getTooltipProperties = (
  item: GetAnalyticsWeaknessTopicItemRes,
  tab: keyof GetAnalyticsWeaknessTopicRes,
  t: TFunction
) => {
  const tooltipItem = {
    name: {
      icon: '',
      text: '',
    },
    value: {
      type: 'text',
      text: `${Math.round(item.questionRatioByType * item.questionCount)}`,
    },
  };
  if (tab === 'mistake') {
    tooltipItem.name = {
      icon: 'ActionCloseThick',
      text: t('createQuiz.topic.customize.property.mistake'),
    };
  } else if (tab === 'overtime') {
    tooltipItem.name = {
      icon: 'TestClockSolid',
      text: t('createQuiz.topic.customize.property.overtime'),
    };
  } else {
    tooltipItem.name = {
      icon: 'OtherOutOfPractice',
      text: t('createQuiz.topic.customize.property.notPracticed'),
    };
  }
  return [
    tooltipItem,
    {
      name: {
        icon: 'ChatQuestionList',
        text: t('createQuiz.topic.customize.property.totalQuestion'),
      },
      value: {
        type: 'text',
        text: `${item.questionCount}`,
      },
    },
  ];
};

export default function useQuizTopicConfig({
  loading,
  items,
  tab,
  selectedIds,
  mistakeCodes,
}: {
  items: GetAnalyticsWeaknessTopicItemRes[];
  loading: boolean;
  tab: keyof GetAnalyticsWeaknessTopicRes;
  selectedIds: string[];
  mistakeCodes: string[];
}): TableLayoutConfig[] {
  const { t } = useTranslation('quiz');
  const mistakeTopicCount = mistakeCodes.length;
  const mistakeSelectedCount = selectedIds.filter((code) =>
    mistakeCodes.includes(code)
  ).length;

  return useMemo(() => {
    const tabColor = getTabColor(tab);
    return [
      {
        layout: 'table-layout',
        table: {
          selectedIds,
          columnOrder: ['name', 'questions', 'action'],
          gridTemplateColumns: '1fr 108px 32px',
          head: {
            checkbox: {
              icon: 'ActionCloseThick',
              text: 'Select All Mistakes',
              value: 'selectAllMistakes',
              state:
                mistakeSelectedCount === mistakeTopicCount
                  ? 'checked'
                  : mistakeSelectedCount > 0
                  ? 'partial'
                  : 'unchecked',
            },
            cells: {
              name: {
                type: 'default',
                icon: 'ChatHashtag',
                label: 'Topic',
              },
              questions: {
                type: 'default',
                icon: 'ChatQuestionList',
                label: 'Question',
              },
              action: {
                type: 'default',
                icon: 'OtherCheckedSquareSolid',
              },
            },
          },
          rows: items.map((item) => {
            const isSelected = selectedIds.includes(item.tagCode);
            const color = isSelected ? tabColor : undefined;

            return {
              id: item.tagCode,
              moreActions: [
                {
                  type: 'event',
                  text: 'Select Topic',
                  value: 'select',
                  icon: 'OtherCheckedSquareSolid',
                },
              ],
              cells: {
                name: {
                  type: 'chip',
                  text: `#${item.tagName}`,
                  color,
                  variant: isSelected ? 'filled' : 'outlined',
                  tooltip: {
                    type: 'list',
                    title:
                      item.tagName.length > 25
                        ? `#${item.tagName}...`
                        : `#${item.tagName}`,
                    properties: getTooltipProperties(item, tab, t),
                  },
                },
                questions: {
                  type: 'progress',
                  value: Math.round(
                    item.questionRatioByType * item.questionCount
                  ),
                  total: item.questionCount,
                  color,
                },
                action: {
                  type: 'checkbox',
                },
              },
            };
          }),
        },
        data: {
          state: loading
            ? 'loading'
            : items.length === 0
            ? 'empty'
            : 'reachedEnd',
          pageSize: 10,
          totalCount: 100,
          emptyRow: {
            text: t('createQuiz.topic.empty', {
              context: tab === 'notPracticedYet' ? 'notPracticed' : tab,
            }),
          },
        },
        settings: {
          layoutSetting: {
            position: 'rhs',
            showRowBorder: false,
            showColumnBorder: false,
            minWidth: 530,
          },
          disableCellFocus: false,
        },
        controlState: {
          selectedCheckedIds: new Set(selectedIds),
          setSelectedCheckedIdsAction: 'setSelectedCheckedIds',
        },
      } as TableLayoutConfig,
    ];
  }, [
    items,
    loading,
    mistakeSelectedCount,
    mistakeTopicCount,
    selectedIds,
    t,
    tab,
  ]);
}
