import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userToChallengerItem } from '@app/web/src/components/CreateQuiz/components/panels/CreateQuizChallengersPanel/useCreateQuizChallengersDataset';
import CreateQuizPanelSimpleButton from '@app/web/src/components/CreateQuiz/CreateQuizPanelSimpleButton';
import { ChallengerIconListLayoutItemObj } from '@app/web/src/components/CreateQuiz/type';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import { Button } from '@front/ui';
import { apis } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

const styles = {
  button: {
    textAlign: 'left',
    px: '5px',
    gap: 0.5,
    fontSize: 12,
    minHeight: 0,
    minWidth: {
      xs: 0,
      sm: '94px',
    },
    'span.button-text': {
      display: {
        xs: 'none',
        sm: 'inline',
      },
    },
  },
};

export type DisplayTableChallengeButtonProps = {
  userId: string;
};

export default function DisplayTableChallengeButton({
  userId,
}: DisplayTableChallengeButtonProps) {
  const { t } = useTranslation();
  const clubSlug = useClubSlug();
  const [challengerSource, setChallengerSource] = useState<
    ChallengerIconListLayoutItemObj[]
  >([]);
  const [loading, setLoading] = useState(false);
  if (!userId || !clubSlug) return null;

  const handleChallengeClick = async () => {
    setLoading(true);
    const [res] = await call(apis.member.getSocialProfile(userId));
    const socialData = res?.data;
    setLoading(false);

    if (!socialData) return;

    setChallengerSource([
      userToChallengerItem({
        user: {
          userId: socialData.userId,
          userName: socialData.userName,
          displayName: socialData.fullName,
          nftAvatar: socialData.avatarUrl,
          avatar: socialData.photoUrl,
          indicator: socialData.indicator,
        },
        disabledUpdate: true,
      }),
    ]);
  };

  return (
    <CreateQuizPanelSimpleButton
      withPanel
      challengerSource={challengerSource}
      defaultValues={{ challengers: [userId] }}
      onBeforeOpenPanel={handleChallengeClick}
      panelKeyPrefix={userId}
      renderButton={({ onClick }) => (
        <Button
          sx={styles.button}
          prefixIcon={<MainChallengeIcon />}
          onClick={onClick}
          loading={loading}
        >
          <span className="button-text">{t('button.Challenge')}</span>
        </Button>
      )}
    />
  );
}
