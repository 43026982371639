import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';
import {
  OtherChallengeAttempt as OtherChallengeAttemptIcon,
  TestInfinity as TestInfinityIcon,
} from '@front/icon';
import { ResponsiveTooltip, TextFieldLabel } from '@front/ui';

const styles = {
  attemptChips: {
    display: 'grid',
    gap: 0.5,
  },
  chipsLabel: {
    py: '5.5px',
  },
  chips: {
    display: 'flex',
    gap: 1,
    '& .MuiChip-root': {
      width: '40px',
      height: '40px',
    },
    '& .MuiChip-label': {
      px: 0,
      display: 'flex',
      alignItems: 'center',
    },
  },
};

type ChallengeMaxAttemptSliderProps = {
  value?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
};

export default function ChallengeMaxAttemptOptions({
  value = 1,
  disabled,
  onChange,
}: ChallengeMaxAttemptSliderProps) {
  const { t } = useTranslation('quiz');
  const handleChange = (val: number) => {
    onChange?.(val);
  };
  const icon = <OtherChallengeAttemptIcon width={12} height={12} />;

  const getTooltipProps = (count: number) => {
    if (count === Infinity) {
      return {
        titleIcon: icon,
        title: (
          <Box display="flex" alignItems="center" gap={0.5}>
            {t(
              'createQuiz.challenge.settings.attempts.hint.title',
              'Challenge Attempts:'
            )}
            <TestInfinityIcon width={16} height={16} />
          </Box>
        ),
        content: t('createQuiz.challenge.settings.attempts.hint.content', {
          context: 'unlimited',
        }),
      };
    }
    return {
      titleIcon: icon,
      title: `${t(
        'createQuiz.challenge.settings.attempts.hint.title',
        'Challenge Attempts:'
      )} ${count}`,
      content: t('createQuiz.challenge.settings.attempts.hint.content', {
        count,
      }),
    };
  };
  return (
    <Box sx={styles.attemptChips}>
      <TextFieldLabel icon={icon}>
        {t(
          'createQuiz.challenge.settings.attempts.title',
          'Challenge Attempts'
        )}
      </TextFieldLabel>

      <Box sx={styles.chips}>
        <ResponsiveTooltip {...getTooltipProps(1)}>
          <Chip
            variant={value === 1 ? 'filled' : 'outlined'}
            label="1"
            clickable
            onClick={() => handleChange(1)}
            disabled={disabled}
          />
        </ResponsiveTooltip>
        <ResponsiveTooltip {...getTooltipProps(2)}>
          <Chip
            variant={value === 2 ? 'filled' : 'outlined'}
            label="2"
            clickable
            onClick={() => handleChange(2)}
            disabled={disabled}
          />
        </ResponsiveTooltip>
        <ResponsiveTooltip {...getTooltipProps(3)}>
          <Chip
            variant={value === 3 ? 'filled' : 'outlined'}
            label="3"
            clickable
            onClick={() => handleChange(3)}
            disabled={disabled}
          />
        </ResponsiveTooltip>
        <ResponsiveTooltip {...getTooltipProps(Infinity)}>
          <Chip
            variant={value === -1 ? 'filled' : 'outlined'}
            label={<TestInfinityIcon width={16} height={16} />}
            clickable
            onClick={() => handleChange(-1)}
            disabled={disabled}
          />
        </ResponsiveTooltip>
      </Box>
    </Box>
  );
}
