import { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Theme, Typography } from '@mui/material';
import useAutoHorizontalScroll from '@app/web/src/hooks/utils/useAutoHorizontalScroll';
import { Hoverable } from '@front/helper';
import { FloatingMenu, Icon, SimpleTooltip } from '@front/ui';
import { ThreadViewType } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { ThreadUser } from '@lib/web/thread/types';
import ThreadEmojiPicker from '@lib/web/thread/ui/ThreadEmojiPicker';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import { ResponsivePageTitle } from '@lib/web/ui';

import useThreadViewChangeEmoji from '../../hooks/useThreadViewChangeEmoji';
import useThreadViewRename from '../../hooks/useThreadViewRename';

const styles = {
  title: {
    width: '100%',
  },
  // same as ui-core typography
  h1: {
    p: 0,
    display: 'block',
    color: 'text.primary',
    width: '100%',
    fontWeight: 'bold',
    fontSize: { xs: 30, md: 24 },
    lineHeight: { xs: '32px', md: '39px' },
    letterSpacing: -0.15,

    fontFamily: (theme: Theme) => theme.typography.body1.fontFamily,

    '&:disabled': {
      color: 'text.primary',
    },
  },
  input: {
    fontSize: { md: 30 },
    border: 'none',
    bgcolor: 'transparent',
    cursor: 'text',
    borderBottom: '1px solid',
    borderColor: 'transparent',

    '&:focus': {
      borderColor: 'text.primary',
    },
    '&:not(:disabled):hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    },
  },
  truncate: {
    textOverflow: 'ellipsis',
  },
};

const MAX_VISIBLE_MEMBER_COUNT = 3;

const EDITABLE_THREAD_VIEW_TYPES = [
  ThreadViewType.DmOneOnOne,
  ThreadViewType.DmGroup,
  ThreadViewType.Club,
];

type RenameDescriptionProps = {
  viewType: ThreadViewType;
  viewName: string;
};

const RenameDescription = ({ viewType, viewName }: RenameDescriptionProps) => {
  const { t } = useTranslation('thread');

  switch (viewType) {
    case ThreadViewType.DmOneOnOne:
      return (
        <>
          <Typography>{viewName}</Typography>
          <Typography variant="caption" sx={{ opacity: 0.64 }}>
            {t(
              'dm.view.rename.description',
              'click to rename, this change will only visible to you.'
            )}
          </Typography>
        </>
      );
    case ThreadViewType.DmGroup:
      return (
        <>
          <Typography>{viewName}</Typography>
          <Typography variant="caption" sx={{ opacity: 0.64 }}>
            {t(
              'group.view.rename.description',
              'click to rename, this change will visible to all group members.'
            )}
          </Typography>
        </>
      );
    case ThreadViewType.Club:
      return (
        <>
          <Typography>{viewName}</Typography>
          <Typography variant="caption" sx={{ opacity: 0.64 }}>
            {t('club.view.rename.description', 'click to rename.')}
          </Typography>
        </>
      );
    default:
      return null;
  }
};

type TitleTextProps = {
  viewNameMembers: NonNullable<ThreadUser | null>[] | null;
  viewType: ThreadViewType;
  viewName: string;
  handleChangeViewName: (ev: ChangeEvent<HTMLInputElement>) => void;
};

const TitleText = ({
  viewNameMembers,
  viewType,
  viewName,
  handleChangeViewName,
}: TitleTextProps) => {
  const { threadTitleTextRef } = useThread();

  const handleEditButtonClick = useCallback((): void => {
    setTimeout(() => {
      threadTitleTextRef.current?.focus();
    });
  }, [threadTitleTextRef]);

  const { isHovered, isScrolling } =
    useAutoHorizontalScroll(threadTitleTextRef);

  // Format the viewName to display member names or "+x others" when member count exceeds 3
  const formattedViewName = useMemo(() => {
    if (
      viewNameMembers?.length &&
      viewNameMembers.length > MAX_VISIBLE_MEMBER_COUNT
    ) {
      const displayedMembers = viewNameMembers
        .slice(0, MAX_VISIBLE_MEMBER_COUNT)
        .map((m) => m.name);
      const remainingCount = viewNameMembers.length - MAX_VISIBLE_MEMBER_COUNT;
      return `${displayedMembers.join(', ')} +${remainingCount} others`;
    }
    return viewName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(viewNameMembers), viewName]);

  if (EDITABLE_THREAD_VIEW_TYPES.includes(viewType)) {
    return (
      <Hoverable sx={styles.title}>
        {({ hovered, hoveredListeners }) => (
          <Box {...hoveredListeners}>
            <FloatingMenu
              open={hovered}
              anchorEl={threadTitleTextRef.current}
              placement="left"
            >
              <FloatingMenu.Button onClick={handleEditButtonClick}>
                <Icon name="OtherEdit" />
              </FloatingMenu.Button>
            </FloatingMenu>
            <SimpleTooltip
              title={
                <RenameDescription viewType={viewType} viewName={viewName} />
              }
              followCursor
            >
              <Box
                ref={threadTitleTextRef}
                sx={[
                  styles.h1,
                  styles.input,
                  !isScrolling && !isHovered && styles.truncate,
                ]}
                component="input"
                value={formattedViewName || ''}
                placeholder="Untitled"
                onChange={handleChangeViewName}
              />
            </SimpleTooltip>
          </Box>
        )}
      </Hoverable>
    );
  }

  return viewName;
};

type ThreadTitleProps = {
  view: GetThreadViewRes;
  clubId?: string;
};

const ThreadViewTitle = ({ view, clubId }: ThreadTitleProps) => {
  const { viewIcon, viewNameMembers, viewType } = useThreadViewDetail(view);

  const { emoji, handleOpenEmojiPanel } = useThreadViewChangeEmoji({
    view,
    clubId,
  });
  const { viewName, handleChangeViewName } = useThreadViewRename({
    view,
    clubId,
  });

  return (
    <ResponsivePageTitle
      icon={
        viewIcon?.type === 'emoji' ? (
          <ThreadEmojiPicker
            emoji={emoji}
            onEmojiClick={handleOpenEmojiPanel}
          />
        ) : (
          <ThreadViewIcon view={view} size="md" />
        )
      }
      titleText={
        <TitleText
          viewNameMembers={viewNameMembers}
          viewType={viewType as ThreadViewType}
          viewName={viewName}
          handleChangeViewName={handleChangeViewName}
        />
      }
    />
  );
};

export default ThreadViewTitle;
