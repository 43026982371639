import { useContext } from 'react';
import { useBaseRightPanel } from '@front/ui';

import CreateQuizContext from '../context';
import { CreateQuizPanelKeys } from '../enums';

import AdvancedSettingsPanel from './panels/AdvancedSettingsPanel';
import CreateQuizChallengersPanel from './panels/CreateQuizChallengersPanel';
import CreateQuizChallengeSettingsPanel from './panels/CreateQuizChallengeSettingsPanel';
import CreateQuizHashtagsPanel from './panels/CreateQuizHashtagsPanel';

export default function CreateQuizRightPanels() {
  const [{ panelKeyPrefix }] = useContext(CreateQuizContext);
  const { rightPanelTarget } = useBaseRightPanel();
  if (rightPanelTarget === `${panelKeyPrefix}${CreateQuizPanelKeys.Topic}`)
    return <CreateQuizHashtagsPanel />;
  if (rightPanelTarget === `${panelKeyPrefix}${CreateQuizPanelKeys.Challenger}`)
    return <CreateQuizChallengersPanel />;
  if (
    rightPanelTarget ===
    `${panelKeyPrefix}${CreateQuizPanelKeys.ChallengeSettings}`
  )
    return <CreateQuizChallengeSettingsPanel />;
  if (
    rightPanelTarget ===
    `${panelKeyPrefix}${CreateQuizPanelKeys.AdvancedSettings}`
  )
    return <AdvancedSettingsPanel />;
  return null;
}
