import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import {
  apis,
  buildHookMutate,
  buildInfiniteHookMutate,
  getUserProfileSocialKey,
  useUserProfileSocial,
} from '@lib/web/apis';
import { call } from '@lib/web/utils';

import useFriendRankingList from '../../widgets/CommonPanels/RankingsPanel/hooks/useFriendRankingList';
import useLeagueRankingList from '../../widgets/CommonPanels/RankingsPanel/hooks/useLeagueRankingList';

import ProfileButton, { ProfileButtonProps } from './ProfileButton';

const CLUB_RANKING_REGEX = /^\/club\/([a-zA-Z0-9-]{1,})\/rankings/;

function useReloadRankingData(userId?: string) {
  const router = useRouter();
  const inRankingPage = CLUB_RANKING_REGEX.test(router.asPath);

  const {
    mutate: followerMutate,
    dataset: followerData,
    totalCount: followerTotalCount,
  } = useFriendRankingList({
    type: 'follower',
    disabled: !inRankingPage,
  });

  const { mutate: followingMutate } = useFriendRankingList({
    type: 'following',
    disabled: !inRankingPage,
  });

  const { mutate: leagueRankingMutate, data: leagueRankingData } =
    useLeagueRankingList({
      disabled: !inRankingPage,
    });

  const reloadFollower = useMemo(
    () => buildInfiniteHookMutate(followerMutate),
    [followerMutate]
  );

  const reloadLeagueRanking = useMemo(
    () => buildHookMutate(leagueRankingMutate),
    [leagueRankingMutate]
  );

  return useCallback(
    (promise: Promise<[any, any]>) => {
      if (!inRankingPage) return;

      call(
        reloadFollower(promise, {
          optimisticData: followerData.map((d) =>
            d.userId === userId ? { ...d, isFollowing: !d.isFollowing } : d
          ),
          optimisticPageData: {
            totalCount: followerTotalCount,
          },
        })
      );

      call(
        reloadLeagueRanking(promise, {
          optimisticData: leagueRankingData?.data.map((d) =>
            d.userId === userId ? { ...d, isFollowing: !d.isFollowing } : d
          ),
        })
      );

      promise.then(() => followingMutate());
    },
    [
      followerData,
      followerTotalCount,
      followingMutate,
      inRankingPage,
      leagueRankingData?.data,
      reloadFollower,
      reloadLeagueRanking,
      userId,
    ]
  );
}

type Options = {
  userId?: string;
  autoFetchSocialData?: boolean;
};

function useReloadAssociateData({ userId, autoFetchSocialData }: Options) {
  const { mutate } = useSWRConfig();
  const reloadRankings = useReloadRankingData(userId);

  const { mutate: socialMutate, data: socialData } = useUserProfileSocial(
    userId,
    { disabled: !autoFetchSocialData }
  );

  const reloadSocialData = useMemo(
    () => buildHookMutate(socialMutate),
    [socialMutate]
  );

  return useCallback(
    async (promise: Promise<[any, any]>) => {
      // to refetch profile social by userId
      if (autoFetchSocialData && socialData?.data) {
        const isFollowing = !socialData.data.isFollowing;
        call(
          reloadSocialData(promise, {
            optimisticData: { ...socialData.data, isFollowing },
          })
        );
      } else {
        await promise;
        mutate(getUserProfileSocialKey(userId));
      }

      reloadRankings(promise);
    },
    [
      autoFetchSocialData,
      socialData?.data,
      reloadRankings,
      reloadSocialData,
      mutate,
      userId,
    ]
  );
}

type FollowButtonProps = {
  userId?: string;
  isFollowing?: boolean;
  isFollower?: boolean;
  onChange?: () => void;
  /**
   * If this is enabled, it will fetch the social data automatically,
   * and then the button will be smoother when follow/un-follow (action will happen immediately without loading).
   * But it may impact performance in case there are too many follow buttons on the page/panel
   * (e.g. rankings page/panel, following/followers panel)
   */
  autoFetchSocialData?: boolean;
} & Pick<
  ProfileButtonProps,
  'size' | 'buttonType' | 'loading' | 'onClick' | 'onMouseDown'
>;

export default function FollowButton({
  userId,
  isFollowing,
  isFollower,
  autoFetchSocialData,
  size,
  loading,
  buttonType = 'emphasizeButton',
  onChange,
  onClick,
  onMouseDown,
}: FollowButtonProps) {
  const { t } = useTranslation();
  const [toggleLoading, setToggleLoading] = useState(false);
  const reloadAssociateData = useReloadAssociateData({
    userId,
    autoFetchSocialData,
  });

  const toggleFollow = useCallback(async () => {
    if (!userId) return;
    setToggleLoading(true);
    const api = isFollowing
      ? () => apis.member.unfollowUser(userId)
      : () => apis.member.followUser(userId);

    const apiPromise = call(api);
    await reloadAssociateData(apiPromise);
    setToggleLoading(false);
    apiPromise.then(() => {
      onChange?.();
    });
  }, [isFollowing, onChange, reloadAssociateData, userId]);

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      toggleFollow();
    },
    [onClick, toggleFollow]
  );

  const dropdownOptions = useMemo(() => {
    if (!isFollowing) return [];
    return [
      {
        display: 'Unfollow',
        iconName: 'OtherUnfollow',
        onClick: toggleFollow,
      },
    ];
  }, [isFollowing, toggleFollow]);

  const isLoading = toggleLoading || loading;
  const label = isFollowing
    ? t('button.Following')
    : isFollower
    ? t('button.Follow Back')
    : t('button.Follow');

  if (!userId) return null;

  return (
    <ProfileButton
      label={label}
      icon={isFollowing ? 'OtherFollowing' : 'OtherAddFriend'}
      buttonType={buttonType}
      loading={isLoading}
      onClick={handleClick}
      onMouseDown={onMouseDown}
      dropdownOptions={dropdownOptions}
      variant={dropdownOptions.length > 0 ? 'outlined' : 'filled'}
      size={size}
    />
  );
}
