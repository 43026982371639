import { forwardRef, useImperativeHandle, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BaseLayoutRightPanelPortal, useBaseRightPanel } from '@front/ui';
import { TableLayoutActionsProvider } from '@lib/ia/src/layouts/TableLayout/TableLayoutActions';
import { ExamMode, HashtagAppearing } from '@lib/web/apis';
import { addDays } from 'date-fns';

import CreateQuizForm from './components/CreateQuizForm';
import CreateQuizRightPanels from './components/CreateQuizRightPanels';
import WatchAvailableQuestionCount from './components/parts/WatchAvailableQuestionCount';
import useStartQuiz, { OnBeforeGetQuizReturn } from './hooks/useStartQuiz';
import useWatchDisabledStatus from './hooks/useWatchDisabledStatus';
import useWatchFormStatus from './hooks/useWatchFormStatus';
import { CreateQuizPanelPrefix } from './enums';
import { CreateQuizFormValue } from './type';

export type CreateQuizProps = {
  variant: 'page' | 'panel';
  defaultValues?: Partial<CreateQuizFormValue>;
  maxQuestionCount: number;
  minQuestionCount: number;
  officialQuestionCount: number;
  sectionId: string;
  forceAvailableQuestionCount?: number;
  onGetQuizUrl: (url: string | null) => void;
  onBeforeGetQuiz?: (
    data: CreateQuizFormValue
  ) => Promise<OnBeforeGetQuizReturn> | OnBeforeGetQuizReturn;
};

export type CreateQuizHandler = {
  submit: () => void;
  reset: () => void;
  eventBus: EventTarget;
};

const initialValue = {
  questionCount: 0,
  tags: [],
  marks: [],
  challengers: [],
  difficulties: [],
  mode: ExamMode.Practice,
  isOpenTimer: false,
  isIncognito: false,
  isRandomChallengers: false,
  randomChallengerCount: 5,
  maxAttempt: 1,
  deadline: addDays(new Date(), 7),
  appearing: [
    HashtagAppearing.Half,
    HashtagAppearing.Likely,
    HashtagAppearing.Rare,
  ],
};

const CreateQuiz = forwardRef<CreateQuizHandler, CreateQuizProps>(
  (
    {
      variant,
      defaultValues = {},
      sectionId,
      maxQuestionCount,
      minQuestionCount,
      officialQuestionCount,
      forceAvailableQuestionCount,
      onGetQuizUrl,
      onBeforeGetQuiz,
    },
    ref
  ) => {
    const { rightPanelTarget } = useBaseRightPanel();
    const formRef = useRef<HTMLFormElement>(null);
    const defaultValuesRef = useRef({ ...initialValue, ...defaultValues });
    const eventBusRef = useRef<EventTarget>(new EventTarget());
    const startQuiz = useStartQuiz({ onGetQuizUrl, onBeforeGetQuiz });
    const methods = useForm<CreateQuizFormValue>({
      mode: 'all',
      defaultValues: defaultValuesRef.current,
    });

    useWatchDisabledStatus(eventBusRef);
    useWatchFormStatus(eventBusRef, methods.formState);
    const { handleSubmit, reset } = methods;

    useImperativeHandle(
      ref,
      () => {
        return {
          submit: handleSubmit(startQuiz),
          reset: () => reset(defaultValuesRef.current),
          eventBus: eventBusRef.current,
        };
      },
      [handleSubmit, startQuiz, reset]
    );

    return (
      <TableLayoutActionsProvider>
        <FormProvider {...methods}>
          <form ref={formRef} onSubmit={methods.handleSubmit(startQuiz)}>
            <CreateQuizForm variant={variant} />
            {rightPanelTarget.includes(CreateQuizPanelPrefix) && (
              <BaseLayoutRightPanelPortal>
                <CreateQuizRightPanels />
              </BaseLayoutRightPanelPortal>
            )}
            <WatchAvailableQuestionCount
              sectionId={sectionId}
              minQuestionCount={minQuestionCount}
              maxQuestionCount={maxQuestionCount}
              officialQuestionCount={officialQuestionCount}
              forceAvailableQuestionCount={forceAvailableQuestionCount}
            />
          </form>
        </FormProvider>
      </TableLayoutActionsProvider>
    );
  }
);

CreateQuiz.displayName = 'CreateQuiz';
export default CreateQuiz;
