import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { InvitationNewUserItem } from '@app/web/src/hooks/utils/useAhaInvitation';
import { SearchState } from '@app/web/src/utils/search';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useAuth } from '@lib/web/apis';

import CreateQuizContext from '../../../context';
import {
  ChallengerIconListLayoutItemObj,
  CreateQuizFormValue,
} from '../../../type';

export default function useCreateQuizChallengersLayoutConfig({
  listState,
  newUser,
  displayDataset,
  totalCount,
  isSelected,
  searchLoading,
  disabled,
}: {
  listState: SearchState | null;
  newUser: InvitationNewUserItem | null;
  displayDataset: ChallengerIconListLayoutItemObj[];
  totalCount: number;
  isSelected: (id: string) => boolean;
  searchLoading: boolean;
  disabled: boolean;
}): IaLayoutConfig[] {
  const { t } = useTranslation('quiz');
  const { member } = useAuth();
  const [createQuizSettings] = useContext(CreateQuizContext);
  const { challengerMap } = createQuizSettings;
  const { watch } = useFormContext<CreateQuizFormValue>();
  const challengerIds = watch('challengers', []);
  const selectedCount = challengerIds.length;

  return useMemo(() => {
    if (!member) {
      return [];
    }

    const challengerValue: ChallengerIconListLayoutItemObj[] = [];

    challengerIds.forEach((challengerId) => {
      if (challengerMap[challengerId]) {
        const selectAction = challengerMap[challengerId].actionMap?.select;
        challengerValue.push({
          ...challengerMap[challengerId],
          actionMap: {
            ...challengerMap[challengerId].actionMap,
            ...((selectAction
              ? {
                  select: {
                    ...selectAction,
                    disabled, // Existing challenge cannot be unselected
                  },
                }
              : {}) as ChallengerIconListLayoutItemObj['actionMap']),
          },
        });
      }
    });

    const selectedItems =
      selectedCount > 0
        ? [t('## Challenger', { count: selectedCount }), ...challengerValue]
        : [];

    if (listState === SearchState.Initial) {
      if (displayDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items: selectedItems,
          },
        ];
      }

      const selectedFollowing = challengerValue.filter(
        (challenger) =>
          challengerMap[challenger.id].metadata?.isFollowing ||
          displayDataset.some((d) => d.id === challenger.id)
      );
      const followingTotalCount = totalCount
        ? totalCount - selectedFollowing.length
        : 0;

      return [
        {
          layout: 'icon-list-layout',
          items: selectedItems.concat(
            [t('## Following', { count: followingTotalCount || 0 })],
            displayDataset.filter((d) => !isSelected(d.id))
          ),
        },
      ];
    }

    if (listState === SearchState.Searching) {
      if (!searchLoading && displayDataset.length === 0 && !newUser)
        return [
          {
            layout: 'icon-list-layout',
            items: [t('No result found')],
          },
        ];

      if (newUser) {
        return [
          {
            layout: 'icon-list-layout',
            items: [
              {
                id: newUser.email,
                title: newUser.email,
                avatarBlackAndWhite: true,
                actionMap: {
                  click: {
                    type: 'event',
                    value: 'inviteToAha',
                    text: t(
                      `challenger.invite.aha.label.${newUser.actionText}`
                    ),
                    actionType: 'textButton',
                    disabled:
                      newUser.actionText === 'accepted' ||
                      newUser.actionText === 'pending',
                  },
                },
              },
            ],
          },
        ];
      }

      const filteredDataset = displayDataset.filter((d) => !isSelected(d.id));
      const filteredTotalCount = totalCount
        ? totalCount - (displayDataset.length - filteredDataset.length)
        : 0;

      return [
        {
          layout: 'icon-list-layout',
          items: selectedItems.concat(
            [t('Results', { count: filteredTotalCount })],
            filteredDataset
          ),
        },
      ];
    }

    return [
      {
        layout: 'icon-list-layout',
        items: [
          ...(selectedCount > 0
            ? [t('## Challenger', { count: selectedCount })]
            : []),
          ...challengerValue,
        ],
      },
    ];
  }, [
    member,
    challengerIds,
    selectedCount,
    t,
    listState,
    challengerMap,
    disabled,
    displayDataset,
    totalCount,
    isSelected,
    searchLoading,
    newUser,
  ]);
}
