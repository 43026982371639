import { useEffect } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { apis, useAuth } from '@lib/web/apis';
import EditorThreadComposerRenderer from '@lib/web/editor/EditorThreadTextComposer/EditorThreadComposerRenderer';
import { useCopyToClipboard } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useSearchMessages } from '@lib/web/thread/hooks/messages/useSearchMessages';
import ThreadChat from '@lib/web/thread/ThreadChat';
import { StreamChatGenerics, ThreadUser } from '@lib/web/thread/types';
import { call } from '@lib/web/utils';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { v4 } from 'uuid';

import useThreadSortFilter from '../../hooks/utils/useThreadSortFilter';
import useThreadRender from '../../widgets/ThreadPage/hooks/useThreadRender';

export type WebThreadChatProps = {
  channelCid: string;
  scope?: string;
};

export default function WebThreadChat({
  channelCid,
  scope,
}: WebThreadChatProps) {
  const { resetThreadMessageSortFilter, openMentionMenu } = useThread();
  const { openRightPanel: openGlobalRightPanel } =
    useBaseRightPanel<GlobalPanelParams>();
  const { blockerTip, member } = useAuth();

  const [, copy] = useCopyToClipboard();

  const myMemberId = member?.memberId || '';

  const availableActions = {
    viewUser: {
      action: ({ userId }: { userId: string }) => {
        openGlobalRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: userId,
        });
      },
    },
    viewAgent: {
      action: ({ userId }: { userId: string }) => {
        openGlobalRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
          agentId: userId,
        });
      },
    },
    viewThread: {
      action: (
        _id: string,
        metadata: { childChannelCid: string | undefined }
      ) => {
        if (!metadata.childChannelCid) return;

        openGlobalRightPanel(GlobalPanelKeys.GlobalThreadChat, {
          channelCid: metadata.childChannelCid,
        });
      },
    },
    openThread: {
      action: (
        _id: string,
        metadata: {
          channel: Channel;
          message: StreamMessage;
          childChannelCid: string | undefined;
        }
      ) => {
        if (metadata.childChannelCid) {
          openGlobalRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: metadata.childChannelCid,
          });
          return;
        }

        const tmpId = v4();
        openGlobalRightPanel(
          GlobalPanelKeys.GlobalThreadCreatorFromMessagePanel,
          {
            tmpId,
            channel: metadata.channel,
            message: metadata.message,
          }
        );
      },
    },
    openUserOrAgentProfile: {
      action: ({ user }: { user?: ThreadUser }): void => {
        if (user?.type === 'agent') {
          openGlobalRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
            agentId: user.agentId ?? '',
          });
        }
        if (user?.type === 'user') {
          openGlobalRightPanel(GlobalPanelKeys.GlobalProfile, {
            userId: user.userId,
          });
        }
      },
    },
    copyMessage: {
      action: (
        _id: string,
        metadata: {
          plainText: string;
          handler: () => void;
        }
      ) => {
        const { plainText, handler } = metadata;
        void copy(plainText);
        handler();
      },
    },
    tryAgain: {
      action: (
        _id: string,
        metadata: {
          channelType: 'public' | 'team';
          channelId?: string;
          message: StreamMessage<StreamChatGenerics>;
          handler: () => void;
        }
      ): void => {
        const { channelType, channelId, message, handler } = metadata;
        handler();
        void call(
          apis.func.threadRecompletion({
            channelType,
            channelId: channelId || '',
            messageId: message.id,
            requestUserId: myMemberId,
            agentUserId: message.user?.id || '',
          })
        );
      },
    },
    prevMessagePage: {
      action: (
        _id: string,
        metadata: {
          handler: (action: 'prev' | 'next') => void;
        }
      ): void => {
        const { handler } = metadata;
        handler('prev');
      },
    },
    nextMessagePage: {
      action: (
        _id: string,
        metadata: {
          handler: (action: 'prev' | 'next') => void;
        }
      ): void => {
        const { handler } = metadata;
        handler('next');
      },
    },
    mentionFriends: {
      action: (): void => {
        openMentionMenu.current();
      },
    },
  };

  const renders = useThreadRender();

  const { useMessageSortFilter } = useThreadSortFilter(scope);
  const { sort, resetSort, filters, resetFilter } = useMessageSortFilter();

  const { messages } = useSearchMessages({
    channelCid,
    messageSort: sort,
    messageFilter: filters,
  });

  const availableEventNames = [
    'openThread',
    'copyMessage',
    'tryAgain',
    'prevMessagePage',
    'nextMessagePage',
    'callOut',
  ];

  useEffect(() => {
    resetThreadMessageSortFilter.current = (): void => {
      resetSort();
      resetFilter();
    };
  }, [resetFilter, resetSort, resetThreadMessageSortFilter]);

  return (
    <IaRenderContextProvider value={renders}>
      <IaActionContextProvider availableActions={availableActions}>
        <EditorThreadComposerRenderer styleProvider>
          <ThreadChat
            channelCid={channelCid}
            tooltip={blockerTip}
            searchedMessages={messages}
            availableEventNames={availableEventNames}
          />
        </EditorThreadComposerRenderer>
      </IaActionContextProvider>
    </IaRenderContextProvider>
  );
}
