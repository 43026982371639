import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MessageItemEvent } from '@lib/ia/src/layouts/ChannelLayout/types';
import { useMessageInformation } from '@lib/web/thread/hooks/message/useMessageInformation';
import {
  AgentComposingStatus,
  StreamChatGenerics,
} from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import GeneralMessage from '../GeneralMessage';

type DropdownOption = {
  title: string;
  description: string;
  value: string;
  iconName: string;
};

type MessageMetadata = {
  plainText?: string;
  channelType?: string;
  channelId?: string;
  message?: StreamMessage<StreamChatGenerics>;
  handler?: (...params: any) => void;
  dropdownOptions?: DropdownOption[];
};

type AgentMessageProps = {
  channel: Channel;
  message: StreamMessage<StreamChatGenerics>;
  messages: StreamMessage<StreamChatGenerics>[];
  childChannel: Channel | undefined;
  availableEventNames?: string[];
};

export default function AgentMessage({
  channel,
  message,
  messages,
  childChannel,
  availableEventNames = [],
}: AgentMessageProps) {
  const [currentMessagePageNum, setCurrentMessagePageNum] = useState(0);
  const previousMessageLengthRef = useRef<number>();
  const [recompleting, setRecompleting] = useState(false);

  const { getMessageInformation } = useMessageInformation();
  const messageInformation = getMessageInformation(
    message,
    currentMessagePageNum
  );

  const navigateMessagePage = useCallback(
    (action: 'prev' | 'next'): void => {
      setCurrentMessagePageNum((page) => {
        if (action === 'prev' && page > 0) {
          return page - 1;
        } else if (
          action === 'next' &&
          page < (message.previousText?.length ?? 0)
        ) {
          return page + 1;
        }
        return page;
      });
    },
    [message.previousText?.length]
  );

  const availableBotEventNames = useMemo(() => {
    const botEventNames: string[] = [];

    if (availableEventNames.length > 0 && message.previousText?.length) {
      botEventNames.push('prevMessagePage', 'nextMessagePage');
    }

    if (
      message.agentComposingStatus &&
      message.agentComposingStatus !== AgentComposingStatus.Completed
    ) {
      return botEventNames;
    }

    const isTryAgainDisabled = childChannel || message.parentMessageId;

    const filteredEventNames = isTryAgainDisabled
      ? availableEventNames.filter((name) => name !== 'tryAgain')
      : availableEventNames;

    return [...botEventNames, ...filteredEventNames];
  }, [
    availableEventNames,
    childChannel,
    message.agentComposingStatus,
    message.parentMessageId,
    message.previousText?.length,
  ]);

  const extraActionBarEvents = useMemo<MessageItemEvent[]>(() => {
    if (
      message.agentComposingStatus &&
      message.agentComposingStatus !== AgentComposingStatus.Completed
    ) {
      return [];
    }

    const botSpecificEvents: MessageItemEvent<MessageMetadata>[] = [
      {
        type: 'event',
        value: 'insert',
        text: 'Insert',
        icon: 'EditorInsertBelow',
        metadata: {
          plainText: messageInformation.plainText,
        },
      },
      {
        type: 'event',
        value: 'insertBelow',
        text: 'Insert Below',
        icon: 'EditorInsertBelow',
        metadata: {
          plainText: messageInformation.plainText,
        },
      },
      {
        type: 'event',
        value: 'replaceSelection',
        text: 'Replace Selection',
        icon: 'OtherResend',
        metadata: {
          plainText: messageInformation.plainText,
        },
      },
      {
        type: 'event',
        value: 'tryAgain',
        text: 'Try Again',
        icon: 'ActionTryAgain',
        disabled: recompleting,
        metadata: {
          channelType: channel.type,
          channelId: channel.id,
          message,
          handler: () => setRecompleting(true),
        },
      },
      {
        type: 'event',
        value: 'prevMessagePage',
        metadata: {
          handler: navigateMessagePage,
        },
      },
      {
        type: 'event',
        value: 'nextMessagePage',
        metadata: {
          handler: navigateMessagePage,
        },
      },
      {
        type: 'event',
        value: 'callOut',
        text: 'Call Out',
        icon: 'ThreadsCallOut',
        metadata: {
          dropdownOptions: [
            {
              title: 'Mention friends',
              description: 'Add your friends to get answer directly',
              value: 'mentionFriends',
              iconName: 'OtherAt',
            },
          ],
        },
      },
    ];

    const allEvents = [...botSpecificEvents];

    return allEvents.filter((event) =>
      availableBotEventNames.includes(event.value)
    );
  }, [
    availableBotEventNames,
    channel.id,
    channel.type,
    message,
    messageInformation.plainText,
    navigateMessagePage,
    recompleting,
  ]);

  useEffect(() => {
    const currentMessageLength = message.previousText?.length ?? 0;

    // Update if the length of previousText has changed
    if (previousMessageLengthRef.current !== currentMessageLength) {
      setCurrentMessagePageNum(currentMessageLength);
      previousMessageLengthRef.current = currentMessageLength;
      setRecompleting(false);
    }
  }, [message.previousText]);

  return (
    <GeneralMessage
      channel={channel}
      message={message}
      currentMessagePageNum={currentMessagePageNum}
      messages={messages}
      childChannel={childChannel}
      availableEventNames={availableBotEventNames}
      extraActionBarEvents={extraActionBarEvents}
    />
  );
}
