import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
  Divider,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material';
import {
  ChatQuestionList as ChatQuestionListIcon,
  OtherChallengeSettings as OtherChallengeSettingsIcon,
  OtherFastForward as OtherFastForwardIcon,
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
  OtherPlayStroke as OtherPlayStrokeIcon,
  ProfileSetting as ProfileSettingIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';

import { Placement } from '../type';

import { difficultyOptions, difficultyTypes } from './parts/DifficultySection';
import Section from './parts/Section';
import SectionItem from './parts/SectionItem';

const styles = {
  root: {
    display: 'grid',
    gap: 2.5,
  },
  officialQuestionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  switch: {
    position: 'relative',
    display: 'flex',
  },
  officialQuestion: (placement: Placement) => ({
    mt: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      md: placement === 'center' ? 'row-reverse' : 'row',
    },
    justifyContent: {
      xs: 'space-between',
      md: placement === 'center' ? 'flex-end' : 'space-between',
    },
    gap: 2,
  }),
  questionCount: {
    fontWeight: 300,
    opacity: 0.5,
  },
  chips: {
    display: 'flex',
    gap: 1,
    width: '100%',
    flexWrap: 'wrap',
  },
};
export default function CreateQuizSkeleton({
  variant,
}: {
  variant: 'page' | 'panel';
}) {
  const { t } = useTranslation('quiz');
  const placement = variant === 'page' ? 'center' : 'rhs';
  return (
    <Box sx={styles.root}>
      <Section title={t('createQuiz.mode.title', 'Mode')}>
        <Box display="flex" gap={1}>
          <Chip
            icon={<OtherFastForwardIcon width={16} height={16} />}
            label={t('createQuiz.mode.turbo.title', 'Turbo')}
            variant="outlined"
            disabled
          />
          <Chip
            icon={<OtherPlayStrokeIcon width={16} height={16} />}
            label={t('createQuiz.mode.mock.title', 'Mock')}
            variant="outlined"
            disabled
          />
        </Box>
      </Section>
      <Divider />
      <Section title={t('createQuiz.duration.title', 'Duration')}>
        <Box>
          <Typography sx={styles.questionCount}>
            <Skeleton width={200} />
          </Typography>

          <Skeleton />
          <Box sx={styles.officialQuestion(placement)}>
            <Box sx={[styles.officialQuestionTitle, { opacity: 0.5 }]}>
              <ChatQuestionListIcon width={16} height={16} />

              <Typography variant="body2">
                <Skeleton width={140} />
              </Typography>
            </Box>

            <Box sx={styles.switch}>
              <Switch disabled />
            </Box>
          </Box>
        </Box>
      </Section>
      <Divider />
      <Section title={t('createQuiz.difficulty.title', 'Difficulty')}>
        <Box sx={styles.chips}>
          {difficultyOptions.map((item, i) => {
            return (
              <Chip
                key={i}
                label={t(`createQuiz.difficulty.${difficultyTypes[i]}.title`)}
                icon={item.icon}
                variant="outlined"
                clickable
                disabled
              />
            );
          })}
        </Box>
      </Section>
      <Divider />
      <Section title={t('createQuiz.topic.title', 'Topic')}>
        <Box sx={styles.chips}>
          <Chip
            variant="dashed"
            label={t('createQuiz.topic.customize.title')}
            icon={<TestAddIcon width={16} height={16} />}
          />
        </Box>
      </Section>
      <Divider />
      <Section title={t('createQuiz.challenge.title', 'Challenge')}>
        <SectionItem
          display={t('createQuiz.challenge.friend.title', 'Challenge a Friend')}
          icon={<OtherFriendsChallengeIcon width={19.2} height={19.2} />}
          disabled
        />

        <SectionItem
          display={t(
            'createQuiz.challenge.settings.title',
            'Challenge Settings'
          )}
          icon={<OtherChallengeSettingsIcon width={19.2} height={19.2} />}
          disabled
        />
      </Section>
      <Divider />
      <SectionItem
        display={t('createQuiz.settings.title', 'Advanced Settings')}
        icon={<ProfileSettingIcon width={19.2} height={19.2} />}
        disabled
      />
    </Box>
  );
}
