import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { userToChallengerItem } from '@app/web/src/components/CreateQuiz/components/panels/CreateQuizChallengersPanel/useCreateQuizChallengersDataset';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { getSearchState } from '@app/web/src/utils/search';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  SearchBar,
  SquareAvatar,
  TextButton,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  buildInfiniteHookMutate,
  OrderByFollowType,
  useSearchClubMembers,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import useClubMembersTabDataset from '../hooks/useClubMembersTabDataset';
import useClubMembersTabLayoutConfig from '../hooks/useClubMembersTabLayoutConfig';

const styles = {
  content: {
    flex: 1,
    position: 'relative',
  },
  searchBar: {
    px: { xs: 2.5, md: '12px' },
  },
  emptyContent: {
    px: { xs: '16px', md: '12px' },
    pt: 1,
  },
  addFriendsButton: {
    mt: 1,
    px: 0,
  },
};

export default function ClubMembersTabContent() {
  const { t } = useTranslation('club');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const {
    focused,
    search,
    debouncedSearch,
    isSearching,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus();
  const { openRightPanel, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const rightParams = getRightParams(GlobalPanelKeys.GlobalAddFriends);

  const clubSlug = useClubSlug();
  const data = useSearchClubMembers({
    clubSlug,
    keyword: debouncedSearch || '',
    isGetRole: true,
    isJoined: true,
    orderByFollowType: OrderByFollowType.FollowingFirst,
  });
  const { dataset, isLoading: searchLoading, totalCount, mutate } = data;

  const displayDataset = useClubMembersTabDataset({ dataset });

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: false,
  });

  const configs = useClubMembersTabLayoutConfig({
    listState,
    displayDataset,
    totalCount,
    searchLoading,
  });
  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const handleChallengeClick = (
    item: IconListLayoutItemObj<BaseMemberInfo>
  ) => {
    if (!item.metadata) return;
    const challenger = userToChallengerItem({
      user: {
        userId: item.metadata.userId,
        userName: item.metadata.distinctName,
        displayName: item.metadata.displayName,
        nftAvatar: item.metadata.nftAvatar,
        avatar: item.metadata.avatar,
        indicator: item.metadata.indicator,
      },
      disabledUpdate: true,
    });
    openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
      challengerSource: [challenger],
    });
  };

  const reloadMembers = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const availableActions = {
    userHovered: {
      action: updateSuggestItem,
    },
    titleClick: {
      action: ({ id }: { id: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: id,
        });
      },
    },
    follow: {
      action: async ({ id }: { id: string }) => {
        await call(
          reloadMembers(apis.member.followUser(id), {
            optimisticData: dataset.map((d) =>
              d.userId === id ? { ...d, isFollowing: true } : d
            ),
            optimisticPageData: {
              totalCount,
            },
          })
        );
      },
    },
    challenge: {
      action: async (item: IconListLayoutItemObj<BaseMemberInfo>) => {
        handleChallengeClick(item);
      },
    },
  };

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: data,
  });

  const handleAddFriendsClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalAddFriends, {
      ...rightParams,
      activeTab: 0,
      defaultSearch: search,
    });
  };

  const emptyContent = (
    <>
      {displayDataset.length === 0 && isSearching && !searchLoading && (
        <Box sx={styles.emptyContent}>
          <Typography variant="body2" color="alpha.lightA64">
            {t('No results found')}
          </Typography>
          <Typography variant="body2" color="alpha.lightA64">
            {t(
              'club.RHS.addFriends.clubMembersTab.empty.content',
              'No club members found with that search term. Feel free to invite users to join the club.'
            )}
          </Typography>
          <TextButton
            prefixIcon={<OtherAddFriendIcon width="16" height="16" />}
            sx={styles.addFriendsButton}
            onClick={handleAddFriendsClick}
          >
            {t('club.RHS.addFriends.clubMembersTab.empty.cta', 'Add Friends')}
          </TextButton>
        </Box>
      )}
    </>
  );

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <Box sx={styles.content}>
          <BaseLayoutRightPanel.ScrollArea
            scrollableNodeProps={{ ref: scrollRef }}
          >
            <Box sx={styles.searchBar}>
              <SearchBar
                placeholder={t(
                  'search.placeholder_clubMembers',
                  'Search club members...'
                )}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
            </Box>
            {configs && <IaLayouts layouts={configs} />}
            {emptyContent}
          </BaseLayoutRightPanel.ScrollArea>
        </Box>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
