import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  ActionClear as ActionClearIcon,
  OtherCalendarDay as OtherCalendarDayIcon,
  OtherChallengeSettings as OtherChallengeSettingsIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  DateTextField,
  ResponsiveTooltip,
  TipButton,
} from '@front/ui';
import { addDays } from 'date-fns';

import CreateQuizContext from '../../../context';
import useClosePanel from '../../../hooks/useClosePanel';
import { CreateQuizFormValue } from '../../../type';

import ChallengeMaxAttemptOptions from './ChallengeMaxAttemptOptions';
const styles = {
  root: {
    pt: 1,
    '& .ia-icon-list-layout': {
      py: 0,
    },
    '& .ia-icon-list-layout_title': {
      py: 0.5,
    },
  },
  settings: {
    px: { xs: '16px', md: '12px' },
    pb: 2,
    pt: 0.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  resetButton: {
    marginLeft: 'auto',
  },
};

export default function CreateQuizChallengeSettingsPanel() {
  const { t } = useTranslation('quiz');
  const { control, register, resetField, getFieldState } =
    useFormContext<CreateQuizFormValue>();
  const isChallengeDirty =
    getFieldState('maxAttempt').isDirty || getFieldState('deadline').isDirty;
  const [createQuizSettings] = useContext(CreateQuizContext);
  const disabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('challenge') ||
    createQuizSettings.disabledParts.includes(
      'challenge-exclude-add-challengers'
    );

  const clearChallengeSettings = () => {
    register('maxAttempt');
    register('deadline');
    resetField('maxAttempt');
    resetField('deadline');
  };

  const handleClosePanel = useClosePanel();

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherChallengeSettingsIcon width="16" height="16" />}
      title={t('createQuiz.RHS.challengerSettings.title', 'Challenge Settings')}
      toolComponent={
        !isChallengeDirty ? undefined : (
          <TipButton
            title={t('toolbar.Reset')}
            onClick={clearChallengeSettings}
            sx={styles.resetButton}
          >
            <ActionClearIcon />
          </TipButton>
        )
      }
      onIconClick={handleClosePanel}
    >
      <Box sx={styles.settings}>
        <Controller
          name="maxAttempt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ChallengeMaxAttemptOptions
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="deadline"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ResponsiveTooltip
              titleIcon={<OtherCalendarDayIcon width={12} height={12} />}
              title={t(
                'createQuiz.challenge.settings.deadline.hint.title',
                'Expiration Deadline'
              )}
              content={t(
                'createQuiz.challenge.settings.deadline.hint.content',
                'Date when the challenge concludes.'
              )}
              tooltipProps={{
                slotProps: {
                  popper: {
                    sx: { zIndex: 1 },
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -24],
                        },
                      },
                    ],
                  },
                },
              }}
            >
              <Box>
                <DateTextField
                  placeholder="MM / DD / YY"
                  format="MM/dd/yy"
                  value={value}
                  onChange={(val) => onChange(new Date(val))}
                  labelIcon={<OtherCalendarDayIcon width={16} height={16} />}
                  label={t(
                    'createQuiz.challenge.settings.deadline.title',
                    'Expiration Deadline'
                  )}
                  suffix={<OtherCalendarDayIcon width={16} height={16} />}
                  minDate={disabled ? undefined : addDays(new Date(), -1)}
                  disabled={disabled}
                />
              </Box>
            </ResponsiveTooltip>
          )}
        />
      </Box>
    </BaseLayoutRightPanel>
  );
}
