import { useMemo } from 'react';
import { SearchState } from '@app/web/src/utils/search';
import { getIndicators } from '@lib/web/utils';

import { ChallengerIconListLayoutItemObj } from '../../../type';

export type ChallengerItemUser = {
  userId: string;
  displayName: string;
  userName: string;
  avatar?: string;
  nftAvatar?: string;
  indicator: BaseMemberInfo['indicator'];
};

export const userToChallengerItem = ({
  user,
  disabledUpdate = false,
}: {
  user: ChallengerItemUser;
  disabledUpdate?: boolean;
}): ChallengerIconListLayoutItemObj => {
  return {
    id: user.userId,
    title: user.displayName || user.userName || '',
    titleAction: { type: 'event', value: 'titleClick' },
    description: `@${user.userName}`,
    avatarUrl: user.nftAvatar || user.avatar || '',
    indicators: getIndicators(user.indicator),
    actionMap: {
      select: {
        value: 'selectChanged',
      },
      hover: {
        value: 'userHovered',
      },
    },
    metadata: {
      userId: user.userId,
      isFollowing: true,
      disabledUpdate,
    },
  };
};

export default function useCreateQuizChallengersDataset({
  listState,
  friendDataset,
  searchDataset,
}: {
  listState: SearchState | null;
  friendDataset: GetFollowerRes[];
  searchDataset: GetSearchClubMembersRes[];
}): ChallengerIconListLayoutItemObj[] {
  return useMemo(() => {
    if (listState === SearchState.Initial) {
      return friendDataset.map((item) => userToChallengerItem({ user: item }));
    }
    if (listState === SearchState.Searching) {
      return searchDataset.map((item) => userToChallengerItem({ user: item }));
    }

    return [];
  }, [listState, friendDataset, searchDataset]);
}
