import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { useDateFormat } from '@front/helper';
import {
  ActionRandomize as ActionRandomizeIcon,
  OtherChallengeSettings as OtherChallengeSettingsIcon,
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
} from '@front/icon';
import { ResponsiveTooltip, SquareAvatar, useBaseRightPanel } from '@front/ui';
import TooltipList from '@lib/ia/src/components/TooltipList';
import TooltipListItem from '@lib/ia/src/components/TooltipListItem';
import { useAuth } from '@lib/web/apis';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

import Section from './Section';
import SectionItem from './SectionItem';

const styles = {
  challengers: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 1,
  },
  randomChallengersIcon: {
    position: 'relative',
  },
  randomIndicator: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    borderRadius: '50%',
    bottom: '-2px',
    right: '-2px',
    bgcolor: 'background.darker',
    color: 'text.primary',
    p: '1.57px',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tooltipItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};
function Challengers() {
  const { member } = useAuth();
  const { watch } = useFormContext<CreateQuizFormValue>();
  const challengers = watch('challengers');
  const isRandomChallengers = watch('isRandomChallengers');
  const randomChallengerCount = watch('randomChallengerCount');

  const [{ challengerMap }] = useContext(CreateQuizContext);
  return (
    <Box sx={styles.challengers}>
      {!isRandomChallengers && (
        <>
          {challengers.map((challengerId) => {
            const challenger = challengerMap[challengerId];
            if (!challenger) return null;
            return (
              <Box key={challengerId}>
                <SquareAvatar src={challenger.avatarUrl} size={32}>
                  {challenger.title}
                </SquareAvatar>
              </Box>
            );
          })}
        </>
      )}
      {isRandomChallengers && (
        <>
          <SquareAvatar src={member?.avatarUrl || member?.photoUrl} size={32}>
            {member?.displayName || member?.distinctName}
          </SquareAvatar>
          <Box sx={styles.randomChallengersIcon}>
            <SquareAvatar blackAndWhite size={32}>
              {randomChallengerCount}
            </SquareAvatar>
            <Box sx={styles.randomIndicator}>
              <ActionRandomizeIcon />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
export default function ChallengeSection() {
  const { t } = useTranslation('quiz');
  const { isTargetPanelOpened, toggleRightPanel } = useBaseRightPanel();
  const { watch } = useFormContext<CreateQuizFormValue>();
  const challengers = watch('challengers');
  const randomChallengerCount = watch('randomChallengerCount');
  const isRandomChallengers = watch('isRandomChallengers');
  const maxAttempt = watch('maxAttempt');
  const deadline = watch('deadline');
  const challengerCount = isRandomChallengers
    ? randomChallengerCount
    : challengers.length;
  const { dateFormat } = useDateFormat();
  const [{ panelKeyPrefix }] = useContext(CreateQuizContext);
  const challengerPanelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.Challenger}`;
  const challengeSettingPanelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.ChallengeSettings}`;

  return (
    <Section title={t('createQuiz.challenge.title', 'Challenge')}>
      <ResponsiveTooltip
        title={t(
          'createQuiz.challenge.friend.hint.title',
          'Challenge a Friend'
        )}
        titleIcon={<OtherFriendsChallengeIcon width={16} height={16} />}
        content={
          <TooltipList content={t('createQuiz.challenge.friend.hint.content')}>
            <TooltipListItem
              name={{
                icon: 'OtherFriendsChallenge',
                text: t(
                  'createQuiz.challenge.friend.property.challengers',
                  'Challengers'
                ),
              }}
              value={{ type: 'text', text: `${challengerCount}` }}
            />
          </TooltipList>
        }
        tooltipProps={{ followCursor: true }}
      >
        <SectionItem
          display={t(
            'createQuiz.challenge.friend.title',
            'Challenge a Friend',
            {
              count: challengerCount,
            }
          )}
          icon={<OtherFriendsChallengeIcon width={19.2} height={19.2} />}
          selected={isTargetPanelOpened(challengerPanelKey)}
          onClick={() => toggleRightPanel(challengerPanelKey)}
        >
          {challengerCount > 0 ? <Challengers /> : undefined}
        </SectionItem>
      </ResponsiveTooltip>
      <ResponsiveTooltip
        title={t(
          'createQuiz.challenge.settings.hint.title',
          'Challenge Settings'
        )}
        titleIcon={<OtherChallengeSettingsIcon width={16} height={16} />}
        content={
          <TooltipList
            content={t('createQuiz.challenge.settings.hint.content')}
          >
            <TooltipListItem
              name={{
                icon: 'OtherChallengeAttempt',
                text: t(
                  'createQuiz.challenge.settings.property.attempts',
                  'Attempts'
                ),
              }}
              value={
                maxAttempt === -1
                  ? { type: 'icon', icon: 'TestInfinity' }
                  : { type: 'text', text: `${maxAttempt}` }
              }
            />
            <TooltipListItem
              name={{
                icon: 'PropertyTypeDateOrTime',
                text: t(
                  'createQuiz.challenge.settings.property.deadline',
                  'Deadline'
                ),
              }}
              value={{ type: 'text', text: dateFormat(deadline) }}
            />
          </TooltipList>
        }
        tooltipProps={{ followCursor: true }}
      >
        <SectionItem
          display={t(
            'createQuiz.challenge.settings.title',
            'Challenge Settings'
          )}
          icon={<OtherChallengeSettingsIcon width={19.2} height={19.2} />}
          selected={isTargetPanelOpened(challengeSettingPanelKey)}
          onClick={() => toggleRightPanel(challengeSettingPanelKey)}
        />
      </ResponsiveTooltip>
    </Section>
  );
}
