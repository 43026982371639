import { useCallback, useContext, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Theme, useMediaQuery } from '@mui/material';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import { getSearchState, SearchState } from '@app/web/src/utils/search';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import {
  ActionClear as ActionClearIcon,
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
  TipButton,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  useAuth,
  useSearchClubMembers,
  useUserFollow,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { call, emailRegex } from '@lib/web/utils';

import CreateQuizContext from '../../../context';
import useClosePanel from '../../../hooks/useClosePanel';
import {
  ChallengerIconListLayoutItemObj,
  CreateQuizFormValue,
} from '../../../type';
import getActiveQuizButtonEl from '../../../utils/getActiveQuizButtonEl';

import RandomChallengersOption from './RandomChallengersOption';
import useCreateQuizChallengersDataset from './useCreateQuizChallengersDataset';
import useCreateQuizChallengersLayoutConfig from './useCreateQuizChallengersLayoutConfig';

const styles = {
  root: {
    pt: 1,
    '& .ia-icon-list-layout': {
      py: 0,
    },
    '& .ia-icon-list-layout_title': {
      py: 0.5,
    },
  },
  title: {
    fontWeight: 700,
    px: { xs: '16px', md: '12px' },
    mt: 1,
    fontSize: { xs: '20px' },
  },
  settings: {
    px: { xs: '16px', md: '12px' },
    pb: 2,
    pt: 0.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  searchBar: {
    '& .search-input-wrap': {
      py: '12px',
    },
  },
  resetButton: {
    marginLeft: 'auto',
  },
  emptyChallengers: {
    px: { xs: '16px', md: '12px' },
  },
};

export default function CreateQuizChallengersPanel() {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { rightPanelOpened } = useBaseRightPanel();

  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const [{ challengeId = '', disabledParts }, setCreateQuizSettings] =
    useContext(CreateQuizContext);
  const { control, watch, setValue, register, resetField, getFieldState } =
    useFormContext<CreateQuizFormValue>();
  const disabled =
    disabledParts.includes('all') ||
    disabledParts.includes('challenge') ||
    disabledParts.includes('challenge-exclude-add-challengers');

  const onlyAddChallengers = disabledParts.includes(
    'challenge-exclude-add-challengers'
  );

  const challengerValue = watch('challengers', []);
  const isRandomChallengers = watch('isRandomChallengers');
  const isChallengersDirty = getFieldState('challengers').isDirty;
  const searchInputRef = useRef<HTMLInputElement>();
  const { member } = useAuth();
  const userId = member?.userId || '';
  const selectedCount = challengerValue.length;
  const handleClosePanel = useClosePanel();
  const { pendingEmails, invitedHistory, inviteToAha, newUserFormatter } =
    useAhaInvitation();

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    setSearch,
    onChange: onSearchChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const clubSlug = useClubSlug();

  const userFollowingData = useUserFollow({
    type: 'following',
    userId,
    keyword: debouncedSearch,
    isGetStatus: true,
  });

  const userSearchData = useSearchClubMembers({
    clubSlug,
    keyword: isSearching ? debouncedSearch : '',
    isExcludeMe: true,
    isOrderByRole: false,
  });

  const { dataset: friendDataset, totalCount: friendTotalCount } =
    userFollowingData;

  const {
    dataset: searchDataset,
    isLoading: searchLoading,
    totalCount: searchTotalCount,
  } = userSearchData;

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: true,
  });

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse:
      listState === SearchState.Initial ? userFollowingData : userSearchData,
    enabled: !disabled && rightPanelOpened && listState !== SearchState.Result,
  });

  const totalCount =
    listState === SearchState.Initial ? friendTotalCount : searchTotalCount;

  const selectUser = () => {
    setSearch('');
    searchInputRef.current?.focus();
  };

  const displayDataset = useCreateQuizChallengersDataset({
    listState,
    friendDataset,
    searchDataset,
  });

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !searchDataset.find((d) => d.email === debouncedSearch) &&
      !searchLoading &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';
    return newUserFormatter(email);
  }, [
    debouncedSearch,
    isSearching,
    newUserFormatter,
    searchDataset,
    searchLoading,
  ]);

  const isSelected = useCallback(
    (id: string) => {
      return challengerValue.some((challengerId) => challengerId === id);
    },
    [challengerValue]
  );

  const config: IaLayoutConfig[] = useCreateQuizChallengersLayoutConfig({
    listState,
    newUser,
    displayDataset,
    totalCount,
    isSelected,
    searchLoading,
    disabled,
  });

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const addChallengerToExistChallenge = async (id: string) => {
    await call(() =>
      apis.challenge.sentChallengeInvitation({
        challengeId,
        userIds: [id],
        emails: [],
      })
    );
  };

  const handleUserClick = (value: ChallengerIconListLayoutItemObj) => {
    if (onlyAddChallengers && challengeId) {
      addChallengerToExistChallenge(value.id);
    }

    const updatedChallengers = isSelected(value.id)
      ? challengerValue.filter((challengerId) => challengerId !== value.id)
      : [...challengerValue, value.id];

    setValue('challengers', updatedChallengers, { shouldDirty: true });
    selectUser();
    setCreateQuizSettings((st) => ({
      ...st,
      challengerMap: {
        ...st.challengerMap,
        [value.id]: onlyAddChallengers
          ? {
              ...value,
              metadata: {
                userId: value.id,
                disabledUpdate: true,
                isFollowing: !!value.metadata?.isFollowing,
              },
            }
          : value,
      },
    }));
  };

  const availableActions = {
    selectChanged: {
      action: (value: ChallengerIconListLayoutItemObj) => {
        handleUserClick(value);
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    inviteToAha: {
      action: async (value: ChallengerIconListLayoutItemObj) => {
        inviteToAha([value.id], {
          toastOptions: { anchorEl: getActiveQuizButtonEl() },
        });
      },
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
  };

  const isDisabled = (id: string) =>
    id === userId ||
    invitedHistory.some(
      (item) => item.receiver.email === id && !item.resendAble
    );

  const getItemStatus = ({ id }: ChallengerIconListLayoutItemObj) => {
    return {
      selected: id === userId || isSelected(id),
      disabled: isDisabled(id),
      loading: pendingEmails.includes(id),
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem && !isDisabled(suggestItem.id)) {
      event.preventDefault();

      handleUserClick(suggestItem);
    }
  };

  const clearChallengers = () => {
    register('challengers');
    resetField('challengers');
  };

  const showChallengers = !isRandomChallengers;

  const mainContent = (
    <>
      <Controller
        name="randomChallengerCount"
        control={control}
        render={({ field: { value, onChange } }) => (
          <RandomChallengersOption
            enabled={isRandomChallengers}
            disabled={disabled}
            value={value ?? 1}
            onChange={(val) => {
              setValue('isRandomChallengers', val !== undefined, {
                shouldDirty: true,
              });

              if (val !== undefined) {
                onChange(val);
              }
            }}
          />
        )}
      />

      {showChallengers && (
        <BaseLayoutRightPanel.SearchInput>
          <SearchBar
            inputRef={searchInputRef}
            placeholder={t(
              'createQuiz.RHS.challengers.placeholder',
              'Find a friend to challenge...'
            )}
            value={search}
            loading={searchLoading}
            onChange={onSearchChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            suggestText={suggestItem?.title}
            prefixIcon={
              suggestItem && (
                <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                  {suggestItem.title}
                </SquareAvatar>
              )
            }
            sx={styles.searchBar}
          />
        </BaseLayoutRightPanel.SearchInput>
      )}
    </>
  );

  const emptyTip = (
    <>
      {selectedCount === 0 && listState === SearchState.Result && (
        <Box sx={styles.emptyChallengers}>
          <LightbulbCard>
            {t(
              'challenge.settings.searchChallengers.tip',
              'Challenge friends using their username, email, or name.'
            )}
          </LightbulbCard>
        </Box>
      )}
    </>
  );

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon={<OtherFriendsChallengeIcon width="16" height="16" />}
          title={t('createQuiz.RHS.challengers.title', 'Add Challengers')}
          toolComponent={
            !isChallengersDirty ? undefined : (
              <TipButton
                title={t('toolbar.Reset')}
                onClick={clearChallengers}
                sx={styles.resetButton}
                disabled={onlyAddChallengers}
              >
                <ActionClearIcon />
              </TipButton>
            )
          }
          onIconClick={handleClosePanel}
        >
          {!mdUp && (
            <BaseLayoutRightPanel.ScrollArea
              scrollableNodeProps={{ ref: scrollRef }}
              sx={styles.root}
            >
              {mainContent}
              {showChallengers && (
                <>
                  {emptyTip}
                  {config && <IaLayouts layouts={config} />}
                </>
              )}
            </BaseLayoutRightPanel.ScrollArea>
          )}
          {mdUp && (
            <BaseLayoutRightPanel.SearchWrapper>
              {mainContent}
              {showChallengers && (
                <BaseLayoutRightPanel.SearchContent>
                  <BaseLayoutRightPanel.ScrollArea
                    scrollableNodeProps={{ ref: scrollRef }}
                  >
                    {emptyTip}
                    {config && <IaLayouts layouts={config} />}
                  </BaseLayoutRightPanel.ScrollArea>
                </BaseLayoutRightPanel.SearchContent>
              )}
            </BaseLayoutRightPanel.SearchWrapper>
          )}
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
