import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box, Slider, Typography } from '@mui/material';
import { ChatQuestionList } from '@front/icon';
import { NumberField, ResponsiveTooltip } from '@front/ui';
import { trackEvent } from '@lib/web/utils';

import CreateQuizContext from '../../context';
import { CreateQuizFormValue } from '../../type';

import DurationDisplay from './DurationDisplay';
import OfficialSwitch from './OfficialSwitch';
import Section from './Section';
const styles = {
  content: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 1,
    '& .MuiTypography-root': {
      pb: '4px',
    },
  },
  slider: {
    pb: 5,
    px: '13px',
    '& .MuiSlider-root': {
      '&:before': {
        content: '""',
        width: 17,
        position: 'absolute',
        height: 8,
        bgcolor: 'text.primary',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        left: -13,
      },
    },
    '& .MuiSlider-rail': {
      bgcolor: 'text.primary',
      opacity: 0.1,
      '&:after': {
        content: '""',
        width: 17,
        position: 'absolute',
        height: 8,
        bgcolor: 'text.primary',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        right: -13,
      },
    },
    '& .MuiSlider-markLabel[data-index="0"]': {
      transform: 'translateX(0%)',
      left: '-13px !important',
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      transform: 'translateX(-100%)',
      textAlign: 'right',
      left: 'calc(100% + 13px) !important',
    },
  },
  disabled: {
    opacity: 0.5,
  },
};
export default function DurationSection() {
  const { t } = useTranslation('quiz');
  const { watch, register, resetField, control } =
    useFormContext<CreateQuizFormValue>();
  const [createQuizSettings] = useContext(CreateQuizContext);
  const {
    disabledParts,
    availableQuestionCount,
    maxQuestionCount,
    officialQuestionCount,
  } = createQuizSettings;
  const maxAvailableQuestionCount =
    availableQuestionCount === undefined
      ? Math.min(maxQuestionCount, officialQuestionCount)
      : Math.min(
          maxQuestionCount,
          officialQuestionCount,
          availableQuestionCount
        );
  const questionCount = watch('questionCount', 2);
  const sectionDisabled =
    disabledParts.includes('all') || disabledParts.includes('duration');
  const disabled = maxAvailableQuestionCount === 0 || sectionDisabled;

  // For existing quiz questions that exceed the allowed limit
  const displayAvailableQuestionCount = sectionDisabled
    ? questionCount
    : maxAvailableQuestionCount;

  const handleNumberChange = (
    value: string,
    onChange: (...event: any[]) => void
  ) => {
    if (`${value}`.length > `${maxAvailableQuestionCount}`.length + 1) return;

    if (!!value && !/^[0-9\b]+$/.test(value)) {
      return;
    }

    trackEvent('form', {
      elementName: 'question-slider',
      action: 'change',
    });
    onChange(value);
  };

  const handleNumberBlur = (
    value: string,
    onChange: (...event: any[]) => void
  ) => {
    if (value === '') {
      register('questionCount');
      resetField('questionCount');
    } else if (+value < 1) onChange(1);
    else if (maxAvailableQuestionCount && +value > maxAvailableQuestionCount)
      onChange(maxAvailableQuestionCount);
  };

  const displayOptions = [
    { value: 1, label: 1 },
    {
      value: displayAvailableQuestionCount,
      label: (
        <Typography variant="body2">
          {displayAvailableQuestionCount}
          {displayAvailableQuestionCount === officialQuestionCount && (
            <>
              <br />
              {t('createQuiz.duration.official.label')}
            </>
          )}
        </Typography>
      ),
    },
  ];

  return (
    <Section title={t('createQuiz.duration.title', 'Duration')}>
      <Box sx={styles.content}>
        <Controller
          name="questionCount"
          control={control}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <ResponsiveTooltip
              titleIcon={<ChatQuestionList width={16} height={16} />}
              title={t('createQuiz.duration.questionCount.hint.title')}
              content={t('createQuiz.duration.questionCount.hint.content')}
              tooltipProps={{ followCursor: true }}
            >
              <span>
                <NumberField
                  inputRef={ref}
                  value={value}
                  onChange={(ev) =>
                    handleNumberChange(ev.target.value, onChange)
                  }
                  onBlur={(ev) => handleNumberBlur(ev.target.value, onChange)}
                  error={error?.message}
                  disabled={disabled}
                />
              </span>
            </ResponsiveTooltip>
          )}
          rules={{
            min: {
              value: 1,
              message: t('createQuiz.duration.questionCount.min'),
            },
            max: {
              value: displayAvailableQuestionCount,
              message: t('createQuiz.duration.questionCount.max', {
                count: displayAvailableQuestionCount,
              }),
            },
          }}
        />
        <Typography sx={[disabled && styles.disabled]} variant="body2">
          <DurationDisplay />
        </Typography>
      </Box>
      <Controller
        name="questionCount"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box sx={styles.slider}>
            <Slider
              marks={displayOptions}
              min={1}
              max={displayAvailableQuestionCount}
              value={value}
              onChange={(ev, newValue) =>
                handleNumberChange(`${newValue}`, onChange)
              }
              onChangeCommitted={() =>
                trackEvent('form', {
                  elementName: 'question-slider',
                  action: 'change',
                })
              }
              disabled={disabled}
            />
          </Box>
        )}
        rules={{
          min: {
            value: 1,
            message: t('createQuiz.duration.questionCount.min'),
          },
          max: {
            value: displayAvailableQuestionCount,
            message: t('createQuiz.duration.questionCount.max', {
              count: displayAvailableQuestionCount,
            }),
          },
        }}
      />

      <Box height={8} />

      <OfficialSwitch />
    </Section>
  );
}
