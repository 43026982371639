import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nonNullable } from '@front/helper';
import ChannelLayout from '@lib/ia/src/layouts/ChannelLayout';
import {
  MessageItemAction,
  MessageItemEvent,
} from '@lib/ia/src/layouts/ChannelLayout/types';
import { featureEnable } from '@lib/web/thread/config/constants';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useMessageInformation } from '@lib/web/thread/hooks/message/useMessageInformation';
import { useReadState } from '@lib/web/thread/hooks/message/useReadState';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

const COPY_DELAY_MS = 1000;

type GeneralMessageProps = {
  channel: Channel;
  message: StreamMessage<StreamChatGenerics>;
  currentMessagePageNum?: number;
  messages: StreamMessage<StreamChatGenerics>[];
  childChannel: Channel | undefined;
  availableEventNames?: string[];
  extraActionBarEvents?: MessageItemEvent[];
};

export default function GeneralMessage({
  channel,
  message,
  currentMessagePageNum,
  messages,
  childChannel,
  availableEventNames = [],
  extraActionBarEvents = [],
}: GeneralMessageProps) {
  const { t } = useTranslation('thread');
  const { getThreadUser } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const { getMessageInformation } = useMessageInformation();
  const { getMessageReadState, getChannelReadState } = useReadState();

  const channelInformation = getChannelInformation(channel);
  const messageInformation = getMessageInformation(
    message,
    currentMessagePageNum
  );
  const messageReadState = getMessageReadState(message, channel);
  const messageCreatorUser = getThreadUser(messageInformation.messageCreator);

  const previousMessage =
    messages?.[messages.findIndex((m) => m.id === message.id) - 1];

  const previousMessageInformation =
    previousMessage && getMessageInformation(previousMessage);
  const previousMessageReadState =
    previousMessage && getMessageReadState(previousMessage, channel);

  const isHeadMessage =
    channelInformation.firstMessageCreatedAt ===
    messageInformation.messageCreatedAt;

  const childChannelInformation = childChannel
    ? getChannelInformation(childChannel)
    : null;
  const childChannelReadState = childChannel
    ? getChannelReadState(childChannel)
    : null;

  const [isCopied, setIsCopied] = useState(false);

  const copyMessageHandler = useCallback((): void => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, COPY_DELAY_MS);
  }, []);

  const clickSenderAction = useMemo<MessageItemAction>(
    () => ({
      type: 'event',
      value: 'openUserOrAgentProfile',
      metadata: { user: messageCreatorUser },
    }),
    [messageCreatorUser]
  );

  const sharedEvents = useMemo<MessageItemEvent[]>(() => {
    return [
      {
        type: 'event',
        value: 'copyMessage',
        text: isCopied ? t('chat.bot.menu.copied') : t('chat.bot.menu.copy'),
        icon: isCopied ? 'TextEditorCheck' : 'OtherCopy',
        metadata: {
          plainText: messageInformation.plainText,
          closeMenuDelay: COPY_DELAY_MS,
          handler: copyMessageHandler,
        },
      },
    ];
  }, [copyMessageHandler, isCopied, messageInformation.plainText, t]);

  const events = useMemo<MessageItemEvent[]>(() => {
    const baseEvents = [...sharedEvents];

    /**
     * Prevent headMessage from opening a child thread, as this can overly complicate the logic, especially for actions like editing messages.
     */
    if (featureEnable.openChildThread && !isHeadMessage) {
      baseEvents.push({
        type: 'event',
        value: 'openThread',
        text: t('message.menu.replyInThread'),
        icon: 'ActionArrowRightUp',
        metadata: {
          channel,
          message,
          childChannelCid: childChannel?.cid,
        },
      });
    }

    return baseEvents.filter((event) =>
      availableEventNames.includes(event.value)
    );
  }, [
    availableEventNames,
    channel,
    childChannel?.cid,
    isHeadMessage,
    message,
    sharedEvents,
    t,
  ]);

  const actionBarEvents = useMemo<MessageItemEvent[]>(() => {
    const allEvents = [...sharedEvents, ...extraActionBarEvents];

    return allEvents.filter((event) =>
      availableEventNames.includes(event.value)
    );
  }, [availableEventNames, extraActionBarEvents, sharedEvents]);

  const messageKey = useMemo(
    () =>
      [
        message.id,
        childChannel?.cid,
        childChannel?.state.last_message_at?.toString(),
        messageInformation.content, // message could be dynamically rendered
      ]
        .filter(nonNullable)
        .join('-'),
    [
      message.id,
      childChannel?.cid,
      childChannel?.state.last_message_at,
      messageInformation.content,
    ]
  );

  const totalPaginationMessageList = useMemo<string[]>(() => {
    return [...(message.previousText ?? []), message.text].filter(nonNullable);
  }, [message.previousText, message.text]);

  return (
    <ChannelLayout.MessageItem
      type="message"
      id={message.id}
      key={messageKey}
      sender={{
        type: 'avatar',
        name: messageCreatorUser?.name ?? '',
        avatarUrl: messageCreatorUser?.image ?? '',
        badge: isHeadMessage && channel.type === 'team' ? 'private' : undefined,
        indicators: messageCreatorUser?.indicators,
      }}
      showReplyThread
      replies={childChannelInformation?.replies}
      showReplyDivider={isHeadMessage}
      replyDividerReplies={channelInformation.replies}
      content={messageInformation.content}
      sentTime={messageInformation.messageCreatedAt}
      contentVariant="chatBody"
      /**
       * when this is a single message, the lastActivityTime would be created time,
       */
      lastActivityTime={
        childChannelInformation
          ? childChannelInformation.lastMessageCreatedAt
          : messageInformation.messageCreatedAt
      }
      dateDividerTime={messageInformation.dateDividerTime}
      readState={
        childChannelReadState ? childChannelReadState : messageReadState
      }
      members={
        childChannelInformation
          ? childChannelInformation.channelEngagedMemberIds.map((memberId) => {
              const threadUser = getThreadUser(memberId);
              return {
                name: threadUser?.name || '',
                avatarUrl: threadUser?.image || '',
              };
            })
          : undefined
      }
      previousMessageProps={{
        dateDividerTime: previousMessageInformation?.dateDividerTime,
        readState: previousMessageReadState,
      }}
      viewThreadAction={{
        type: 'event',
        value: 'viewThread',
        metadata: { childChannelCid: childChannel?.cid },
      }}
      clickSenderAction={
        message.type === 'regular' ? clickSenderAction : undefined
      }
      eventMenuClassName="right-panel-floating-menu"
      events={events}
      actionBarEvents={actionBarEvents}
      cta={messageInformation.cta}
      paginationInfo={{
        messageList: totalPaginationMessageList,
        currentMessageIndex: currentMessagePageNum,
      }}
    />
  );
}
