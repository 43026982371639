import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { ProfileSetting as ProfileSettingIcon } from '@front/icon';
import { ResponsiveTooltip, useBaseRightPanel } from '@front/ui';
import TooltipList from '@lib/ia/src/components/TooltipList';
import TooltipListItem from '@lib/ia/src/components/TooltipListItem';
import { ExamMode, HashtagAppearing } from '@lib/web/apis';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

import SectionItem from './SectionItem';

const styles = {
  childItem: {
    pl: '20px',
  },
};
export default function AdvancedSection() {
  const { t } = useTranslation('quiz');
  const [{ panelKeyPrefix }] = useContext(CreateQuizContext);
  const panelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.AdvancedSettings}`;
  const { isTargetPanelOpened, toggleRightPanel } = useBaseRightPanel();
  const { watch } = useFormContext<CreateQuizFormValue>();
  const isOpenTimer = watch('isOpenTimer');
  const isIncognito = watch('isIncognito');
  const appearing = watch('appearing');
  const mode = watch('mode');
  const modeText =
    mode === ExamMode.MockExam
      ? t('createQuiz.mode.mock.title')
      : t('createQuiz.mode.turbo.title');

  const appearingSelected = (option: HashtagAppearing) =>
    appearing.length === 0 || appearing.includes(option);
  return (
    <ResponsiveTooltip
      title={t('createQuiz.settings.hint.title', 'Advanced Settings')}
      titleIcon={<ProfileSettingIcon width={16} height={16} />}
      content={
        <TooltipList content={t('createQuiz.settings.hint.content')}>
          <TooltipListItem
            name={{
              icon: 'OtherProbabilityRare',
              text: t('createQuiz.settings.appearing.title'),
            }}
          />
          {appearingSelected(HashtagAppearing.Rare) && (
            <TooltipListItem
              name={{
                icon: 'OtherProbabilityRare',
                text: t('createQuiz.settings.appearing.rare.title'),
                sxProps: styles.childItem,
              }}
            />
          )}
          {appearingSelected(HashtagAppearing.Half) && (
            <TooltipListItem
              name={{
                icon: 'OtherProbabilityHalf',
                text: t('createQuiz.settings.appearing.half.title'),
                sxProps: styles.childItem,
              }}
            />
          )}
          {appearingSelected(HashtagAppearing.Likely) && (
            <TooltipListItem
              name={{
                icon: 'OtherProbabilityLikely',
                text: t('createQuiz.settings.appearing.likely.title'),
                sxProps: styles.childItem,
              }}
            />
          )}
          <TooltipListItem
            name={{
              icon: 'TestClock',
              text: t('createQuiz.settings.property.timer', {
                context: isOpenTimer ? 'open' : 'close',
                mode: modeText,
              }),
            }}
          />
          <TooltipListItem
            name={{
              icon: isIncognito ? 'OtherIncognito' : 'LoginSee',
              text: t('createQuiz.settings.property.incognito', {
                context: isIncognito ? 'open' : 'close',
              }),
            }}
          />
        </TooltipList>
      }
      tooltipProps={{ followCursor: true }}
    >
      <SectionItem
        display={t('createQuiz.settings.title', 'Advanced Settings')}
        icon={<ProfileSettingIcon width={19.2} height={19.2} />}
        selected={isTargetPanelOpened(panelKey)}
        onClick={() => toggleRightPanel(panelKey)}
      />
    </ResponsiveTooltip>
  );
}
