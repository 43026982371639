import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useThreadSortFilter from '@app/web/src/hooks/utils/useThreadSortFilter';
import { BaseLayoutContainer, BaseLayoutScrollArea } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import EditorThreadComposerRenderer from '@lib/web/editor/EditorThreadTextComposer/EditorThreadComposerRenderer';
import { useFilteredChannels } from '@lib/web/thread/hooks/channels/useFilteredChannels';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';

import useSortActions from '../../../CommonPanels/SortResponsivePanel/hooks/useSortActions';
import useSetFilterSortConditions from '../../hooks/useSetFilterSortConditions';
import useThreadRender from '../../hooks/useThreadRender';
import ThreadMentionUser from '../ThreadMentionUser';
import ThreadViewTitle from '../ThreadViewTitle';

import { useThreadViewTableAvailableAction } from './hooks/useThreadViewTableAvailableAction';
import { useThreadViewTableLayoutConfig } from './hooks/useThreadViewTableLayoutConfig';

const styles = {
  pageTitle: {
    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    mb: '20px',
  },
};

export type ThreadViewChannelLayoutProps = {
  view: GetThreadViewRes;
  clubId?: string;
  scope?: string;
};

export default function ThreadViewTableLayout({
  view,
  clubId,
  scope,
}: ThreadViewChannelLayoutProps) {
  const { resetThreadChannelSortFilter } = useThread();
  const { viewMemberIds } = useThreadViewDetail(view);
  const [expandedMap, setExpandedMap] = useState<Record<string, boolean>>({});

  const { useChannelSortFilter } = useThreadSortFilter(scope);
  const { key, filters, resetFilter, sort, resetSort } =
    useChannelSortFilter(view);

  const { reset: resetSortAction } = useSortActions();

  const { setDefaultSortCriteria, defaultFilterSortReady } =
    useSetFilterSortConditions({
      type: 'table',
      view,
      viewMemberIds,
      scope,
    });

  const channelsData = useFilteredChannels({
    scope: 'centre-table-view',
    key,
    filters,
    sort: [{ ...sort, last_message_at: -1 }],
    enable: defaultFilterSortReady,
  });

  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const config = useThreadViewTableLayoutConfig({
    channelsData,
    selectedRowId,
    expandedMap,
  });
  const availableActions = useThreadViewTableAvailableAction({
    view,
    setSelectedRowId,
    loadMore: () => channelsData.setSize((st) => st + 1),
    expandRow: ({ rowId, value }) =>
      setExpandedMap({ ...expandedMap, [rowId]: value }),
  });
  const renders = useThreadRender();

  useEffect(() => {
    resetThreadChannelSortFilter.current = (): void => {
      resetSort();
      resetSortAction();
      resetFilter();

      setDefaultSortCriteria(); // sort criteria will be clear, so set default sort criteria here
    };
  }, [
    resetFilter,
    resetSort,
    resetSortAction,
    resetThreadChannelSortFilter,
    setDefaultSortCriteria,
  ]);

  return (
    <BaseLayoutScrollArea>
      <BaseLayoutContainer sx={styles.pageTitle}>
        <ThreadViewTitle view={view} clubId={clubId} />
      </BaseLayoutContainer>
      <Box>
        <IaRenderContextProvider value={renders}>
          <IaActionContextProvider availableActions={availableActions}>
            <EditorThreadComposerRenderer
              mentionRender={ThreadMentionUser}
              styleProvider
            >
              <IaLayouts layouts={config} />
            </EditorThreadComposerRenderer>
          </IaActionContextProvider>
        </IaRenderContextProvider>
      </Box>
    </BaseLayoutScrollArea>
  );
}
