import { format } from 'date-fns';

import { CreateQuizFormValue } from '../type';

type GetCreateQuizParams<C> = {
  sectionId: string;
  officialQuestionCount: number;
  isChallenge: C;
  isMockExam: boolean;
};

type GetCreateQuizParamsReturnType<C extends boolean> = C extends true
  ? ChallengeByTagReq
  : QuizByTagReq;
export default function getCreateQuizParams<C extends true | false>(
  data: CreateQuizFormValue,
  {
    isChallenge,
    isMockExam,
    sectionId,
    officialQuestionCount,
  }: GetCreateQuizParams<C>
): GetCreateQuizParamsReturnType<C> {
  const appearingHashtags =
    data.appearing.length === 0 || data.appearing.length === 3
      ? []
      : data.appearing;
  if (isChallenge) {
    const params: ChallengeByTagReq = {
      sectionId,
      tagCodeAry: isMockExam ? [] : [...data.tags, ...appearingHashtags],
      questionCount: isMockExam ? officialQuestionCount : data.questionCount,
      challengerEmails: [],
      challengerIds: data.challengers,
      mode: data.mode,
      isOpenTimer: data.isOpenTimer,
      maxAttemptNum: data.maxAttempt,
      expireAt: format(data.deadline, 'yyyy-MM-dd'),
      difficultyAry: isMockExam ? [] : data.difficulties,
      randomChallengerCount: data.isRandomChallengers
        ? data.randomChallengerCount
        : undefined,
    };

    return params as GetCreateQuizParamsReturnType<C>;
  }

  const params: QuizByTagReq = {
    sectionId,
    markCodeAry: isMockExam ? [] : data.marks,
    tagCodeAry: isMockExam ? [] : [...data.tags, ...appearingHashtags],
    questionCount: isMockExam ? officialQuestionCount : data.questionCount,
    isOpenTimer: data.isOpenTimer,
    difficultyAry: isMockExam ? [] : data.difficulties,
  };
  return params as GetCreateQuizParamsReturnType<C>;
}
