import { useCallback, useEffect, useRef, useState } from 'react';

const useAutoHorizontalScroll = (
  ref: React.RefObject<HTMLElement>,
  { scrollStep = 2, scrollSpeed = 16 } = {}
) => {
  const scrollIntervalRef = useRef<number>();
  const [isHovered, setIsHovered] = useState(false);
  const isFocusRef = useRef(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const stopScroll = useCallback((): void => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = undefined;
      setIsScrolling(false);
    }
  }, []);

  const scrollToInitPosition = useCallback((): void => {
    if (ref.current) {
      ref.current.scrollLeft = 0; // Reset to the leftmost position
    }
  }, [ref]);

  useEffect(() => {
    const input = ref.current;

    if (!input) return;

    if (isHovered && input) {
      scrollIntervalRef.current = window.setInterval(() => {
        if (input.scrollLeft < input.scrollWidth - input.clientWidth) {
          input.scrollLeft += scrollStep;
          setIsScrolling(true);
        } else {
          stopScroll();
        }
      }, scrollSpeed);
    }

    const handleFocusIn = (): void => {
      isFocusRef.current = true;
      stopScroll();
    };

    const handleFocusOut = (): void => {
      isFocusRef.current = false;
    };

    const handleMouseEnter = (): void => {
      if (document.activeElement === input) {
        return;
      }
      setIsHovered(true);
    };

    const handleMouseLeave = (): void => {
      setIsHovered(false);
      if (!isFocusRef.current) {
        scrollToInitPosition();
      }
    };

    input.addEventListener('focusin', handleFocusIn);
    input.addEventListener('focusout', handleFocusOut);
    input.addEventListener('mouseenter', handleMouseEnter);
    input.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      input.removeEventListener('focusin', handleFocusIn);
      input.removeEventListener('focusout', handleFocusOut);
      input.removeEventListener('mouseenter', handleMouseEnter);
      input.removeEventListener('mouseleave', handleMouseLeave);
      stopScroll();
    };
  }, [
    isHovered,
    ref,
    scrollSpeed,
    scrollStep,
    scrollToInitPosition,
    stopScroll,
  ]);

  return { isHovered, isScrolling, stopScroll };
};

export default useAutoHorizontalScroll;
