import { Ref } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useCommonToolbarItems } from '@app/web/src/hooks/utils/useCommonToolbarItems';
import {
  GlobalPanelKeys,
  GlobalPanelParams,
  ThreadPanelKeys,
  ThreadPanelParams,
} from '@app/web/src/types/panel';
import { ActionChevronRightSmall as ActionChevronRightSmallIcon } from '@front/icon';
import { useBaseRightPanel } from '@front/ui';
import { ResponsiveToolbar } from '@lib/web/ui';

import { useFilter } from '../../hooks/utils/useFilter';
import { useSort } from '../../hooks/utils/useSort';
import { useToolbarMoreButtonDot } from '../../hooks/utils/useToolbarMoreButtonDot';

export default function ThreadViewPanelToolbar({
  sortButtonRef,
  filterButtonRef,
  scope,
}: {
  sortButtonRef: Ref<HTMLButtonElement>;
  filterButtonRef: Ref<HTMLButtonElement>;
  scope: string;
}) {
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { getRightParams, setRightParams } =
    useBaseRightPanel<ThreadPanelParams>();

  const { filterOrSortOpened } = getRightParams(ThreadPanelKeys.ThreadPanel);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const commonToolbarItems = useCommonToolbarItems({ enableThread: false });
  const { moreButtonDot } = useToolbarMoreButtonDot(scope, 'rhs');
  const { hasFilter } = useFilter(scope);
  const { hasSort } = useSort(scope);

  return (
    <ResponsiveToolbar
      items={[
        {
          type: 'Sort',
          onClick: () => {
            setRightParams({ filterOrSortOpened: 'sort' });
          },
          onHover: () => {
            setRightParams({ filterOrSortOpened: 'sort' });
          },
          selected: filterOrSortOpened === 'sort',
          highlight: hasSort,
          hiddenInMore: true,
          disabledAutoClose: mdUp,
          buttonRef: sortButtonRef,
          actionIcon: mdUp ? <ActionChevronRightSmallIcon /> : null,
          className: 'right-panel-toolbar-item',
        },
        {
          type: 'Filter',
          onClick: () => {
            setRightParams({ filterOrSortOpened: 'filter' });
          },
          onHover: () => {
            setRightParams({ filterOrSortOpened: 'filter' });
          },
          selected: filterOrSortOpened === 'filter',
          highlight: hasFilter,
          hiddenInMore: true,
          disabledAutoClose: mdUp,
          buttonRef: filterButtonRef,
          actionIcon: mdUp ? <ActionChevronRightSmallIcon /> : null,
          className: 'right-panel-toolbar-item',
        },
        {
          type: 'Layout',
          onClick: () =>
            openRightPanel(GlobalPanelKeys.GlobalLayout, {
              supportableLayouts: {
                channel: true,
                table: true,
              },
            }),
        },
        ...commonToolbarItems,
      ]}
      moreButtonDot={moreButtonDot}
      shouldKeepOpened={filterOrSortOpened !== null && mdUp}
      isOptionEqualToValue={(option) =>
        option.item.type.toLowerCase() === filterOrSortOpened
      }
    />
  );
}
