import { useContext, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import { excludeHashtag } from '@app/web/src/config/hasthag';
import {
  ActionClear as ActionClearIcon,
  ActionCloseThick as ActionCloseThickIcon,
  ChatHashtag as ChatHashtagIcon,
  OtherOutOfPractice as OtherOutOfPracticeIcon,
  TestClockSolid as TestClockSolidIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  Scrollbar,
  SearchBar,
  TipButton,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useAnalyticsWeaknessTopic } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';
import { isEqual, uniq } from 'lodash';

import CreateQuizContext from '../../../context';
import useClosePanel from '../../../hooks/useClosePanel';
import { CreateQuizFormValue } from '../../../type';

import useQuizTopicConfig from './useQuizTopicConfig';
import useQuizTopicRowItems from './useQuizTopicRowItems';

const styles = {
  resetButton: {
    marginLeft: 'auto',
  },
  tabs: {
    px: { xs: 2.5, md: '12px' },
    display: 'flex',
    gap: 2,
    width: 'fit-content',
  },
};

export default function CreateQuizHashtagsPanel() {
  const { t } = useTranslation(['quiz', 'common']);
  const { sectionId } = useCurrentIaClub();
  const { data: originalData } = useAnalyticsWeaknessTopic(sectionId);
  const updatedData = useMemo(() => {
    if (!originalData) return undefined;

    return {
      mistake: originalData.data.mistake.filter(
        (d) => !excludeHashtag.includes(d.tagCode)
      ),
      overtime: originalData.data.overtime.filter(
        (d) => !excludeHashtag.includes(d.tagCode)
      ),
      notPracticedYet: originalData.data.notPracticedYet.filter(
        (d) => !excludeHashtag.includes(d.tagCode)
      ),
    };
  }, [originalData]);
  const handleClosePanel = useClosePanel();
  const { rightPanelOpened } = useBaseRightPanel();
  const { watch, setValue, register, resetField, getFieldState, formState } =
    useFormContext<CreateQuizFormValue>();
  const searchInputRef = useRef<HTMLInputElement>();
  const [search, setSearch] = useState('');
  const [selectedTab, setSelectedTab] =
    useState<keyof GetAnalyticsWeaknessTopicRes>('mistake');
  const [{ disabledParts }] = useContext(CreateQuizContext);
  const hashtagsDisabled =
    disabledParts.includes('all') || disabledParts.includes('topics');
  const isTagsDirty = getFieldState('tags').isDirty;
  const defaultValues = useMemo(
    () => (formState.defaultValues?.tags || []) as string[],
    [formState.defaultValues?.tags]
  );
  const tagValue = watch('tags', defaultValues);
  const codeByType: Record<keyof GetAnalyticsWeaknessTopicRes, string[]> =
    useMemo(() => {
      return {
        mistake: updatedData?.mistake.map((d) => d.tagCode) || ([] as string[]),
        overtime:
          updatedData?.overtime.map((d) => d.tagCode) || ([] as string[]),
        notPracticedYet:
          updatedData?.notPracticedYet.map((d) => d.tagCode) ||
          ([] as string[]),
      };
    }, [updatedData]);
  const countByType: Record<keyof GetAnalyticsWeaknessTopicRes, number> =
    useMemo(() => {
      return {
        mistake:
          codeByType.mistake.filter((tagCode) => tagValue.includes(tagCode))
            .length || 0,
        overtime:
          codeByType.overtime.filter((tagCode) => tagValue.includes(tagCode))
            .length || 0,
        notPracticedYet:
          codeByType.notPracticedYet.filter((tagCode) =>
            tagValue.includes(tagCode)
          ).length || 0,
      };
    }, [
      codeByType.mistake,
      codeByType.notPracticedYet,
      codeByType.overtime,
      tagValue,
    ]);

  const rowItems = useQuizTopicRowItems({
    data: updatedData,
    search,
    tab: selectedTab,
  });

  const config = useQuizTopicConfig({
    items: rowItems,
    loading: !updatedData,
    tab: selectedTab,
    selectedIds: tagValue,
    mistakeCodes: codeByType.mistake,
  });

  const clearHashtagsAndEmojis = () => {
    register('tags');
    resetField('tags');
  };

  const updateValue = (newValues: string[]) => {
    if (isEqual(newValues, tagValue)) return;
    setValue('tags', newValues, {
      shouldDirty: true,
    });
    trackEvent('form', {
      elementName: 'hashtag',
      action: 'change',
    });
  };

  const availableActions = {
    setSelectedCheckedIds: {
      action: (selectedIds: Set<string>) => {
        if (!rightPanelOpened) return;
        const filteredCode = tagValue.filter(
          (tagCode) => !rowItems.some((row) => row.tagCode === tagCode)
        );

        updateValue(uniq([...filteredCode, ...selectedIds]));
      },
    },
    selectAllMistakes: {
      action: () => {
        if (!rightPanelOpened) return;

        const filteredCode = codeByType.mistake.filter(
          (mistakeCode) => !tagValue.some((tagCode) => tagCode === mistakeCode)
        );

        if (filteredCode.length) {
          updateValue(uniq([...tagValue, ...filteredCode]));
        } else {
          updateValue(
            tagValue.filter((tagCode) => !codeByType.mistake.includes(tagCode))
          );
        }
      },
    },
  };

  const handleTabChanges = (tab: keyof GetAnalyticsWeaknessTopicRes) => {
    setSelectedTab(tab);
  };

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <BaseLayoutRightPanel
        titleIcon={<ChatHashtagIcon width="16" height="16" />}
        title={t('createQuiz.topic.title')}
        toolComponent={
          isTagsDirty && !hashtagsDisabled ? (
            <TipButton
              title={t('toolbar.Reset')}
              onClick={clearHashtagsAndEmojis}
              sx={styles.resetButton}
            >
              <ActionClearIcon />
            </TipButton>
          ) : undefined
        }
        onIconClick={handleClosePanel}
        resizable
      >
        <BaseLayoutRightPanel.SearchWrapper>
          <BaseLayoutRightPanel.SearchInput>
            <SearchBar
              inputRef={searchInputRef}
              placeholder={t('search.placeholder_topics', 'Search topics...')}
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
            />
          </BaseLayoutRightPanel.SearchInput>
          <Scrollbar>
            <Box sx={styles.tabs}>
              <Chip
                icon={<ActionCloseThickIcon width={16} height={16} />}
                label={t('createQuiz.topic.tab.mistake', {
                  count: countByType.mistake,
                })}
                variant={selectedTab === 'mistake' ? 'filled' : 'outlined'}
                onClick={() => handleTabChanges('mistake')}
              />
              <Chip
                icon={<TestClockSolidIcon width={16} height={16} />}
                label={t('createQuiz.topic.tab.overtime', {
                  count: countByType.overtime,
                })}
                variant={selectedTab === 'overtime' ? 'filled' : 'outlined'}
                onClick={() => handleTabChanges('overtime')}
              />
              <Chip
                icon={<OtherOutOfPracticeIcon width={16} height={16} />}
                label={t('createQuiz.topic.tab.notPracticed', {
                  count: countByType.notPracticedYet,
                })}
                variant={
                  selectedTab === 'notPracticedYet' ? 'filled' : 'outlined'
                }
                onClick={() => handleTabChanges('notPracticedYet')}
              />
            </Box>
          </Scrollbar>
          <BaseLayoutRightPanel.SearchContent>
            <BaseLayoutRightPanel.ScrollArea>
              <IaLayouts layouts={config} />
            </BaseLayoutRightPanel.ScrollArea>
          </BaseLayoutRightPanel.SearchContent>
        </BaseLayoutRightPanel.SearchWrapper>
      </BaseLayoutRightPanel>
    </IaActionContextProvider>
  );
}
