import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import {
  ChatQuestionList as ChatQuestionListIcon,
  EditorDifficultyMedium as EditorDifficultyMediumIcon,
  OtherProbabilityRare as OtherProbabilityRareIcon,
} from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';
import TooltipList from '@lib/ia/src/components/TooltipList';
import TooltipListItem from '@lib/ia/src/components/TooltipListItem';
import { useCurrentExam } from '@lib/web/hooks';

import CreateQuizContext from '../../context';

import Section from './Section';
import SectionItem from './SectionItem';

const styles = {
  tip: (maxWidth: number) => ({
    whiteSpace: 'unset',
    maxWidth,
  }),
};
export default function FixedSettingsSection() {
  const { t } = useTranslation('quiz');
  const [createQuizSettings] = useContext(CreateQuizContext);
  const { section } = useCurrentExam();
  return (
    <Section title={t('createQuiz.mock.fixed.title', 'Fixed Settings')}>
      <Box>
        <ResponsiveTooltip
          titleIcon={<ChatQuestionListIcon width={16} height={16} />}
          title={t('createQuiz.mock.duration.hint.title')}
          content={t('createQuiz.mock.duration.hint.content', {
            questions: createQuizSettings.officialQuestionCount,
            minutes: section?.timeMinutes ?? 0,
          })}
          tooltipProps={{ followCursor: true }}
        >
          <SectionItem
            display={t('createQuiz.mock.duration.title')}
            content={t('createQuiz.mock.duration.content', {
              questions: createQuizSettings.officialQuestionCount,
              minutes: section?.timeMinutes ?? 0,
            })}
            icon={<ChatQuestionListIcon width={19.2} height={19.2} />}
            disabled
          />
        </ResponsiveTooltip>
        <ResponsiveTooltip
          titleIcon={<EditorDifficultyMediumIcon width={16} height={16} />}
          title={t('createQuiz.mock.difficulty.hint.title')}
          content={
            <TooltipList content={t('createQuiz.mock.difficulty.hint.content')}>
              <TooltipListItem
                name={{
                  icon: 'EditorDifficultyEasy',
                  text: t('createQuiz.difficulty.easy.title'),
                }}
                value={{
                  type: 'text',
                  text: t('createQuiz.difficulty.easy.hint.content'),
                  sxProps: styles.tip(111),
                }}
              />
              <TooltipListItem
                name={{
                  icon: 'EditorDifficultyMedium',
                  text: t('createQuiz.difficulty.medium.title'),
                }}
                value={{
                  type: 'text',
                  text: t('createQuiz.difficulty.medium.hint.content'),
                  sxProps: styles.tip(111),
                }}
              />
              <TooltipListItem
                name={{
                  icon: 'EditorDifficultyHard',
                  text: t('createQuiz.difficulty.hard.title'),
                }}
                value={{
                  type: 'text',
                  text: t('createQuiz.difficulty.hard.hint.content'),
                  sxProps: styles.tip(111),
                }}
              />
              <TooltipListItem
                name={{
                  icon: 'EditorDifficultySuper',
                  text: t('createQuiz.difficulty.super.title'),
                }}
                value={{
                  type: 'text',
                  text: t('createQuiz.difficulty.super.hint.content'),
                  sxProps: styles.tip(111),
                }}
              />
            </TooltipList>
          }
          tooltipProps={{ followCursor: true }}
        >
          <SectionItem
            display={t('createQuiz.mock.difficulty.title')}
            content={t('createQuiz.mock.difficulty.content')}
            icon={<EditorDifficultyMediumIcon width={19.2} height={19.2} />}
            disabled
          />
        </ResponsiveTooltip>
        <ResponsiveTooltip
          titleIcon={<OtherProbabilityRareIcon width={16} height={16} />}
          title={t('createQuiz.mock.appearing.hint.title')}
          content={
            <TooltipList content={t('createQuiz.mock.appearing.hint.content')}>
              <TooltipListItem
                name={{
                  icon: 'OtherProbabilityRare',
                  text: t('createQuiz.settings.appearing.rare.title'),
                }}
                value={{
                  type: 'text',
                  text: t('createQuiz.settings.appearing.rare.hint.content'),
                  sxProps: styles.tip(124),
                }}
              />
              <TooltipListItem
                name={{
                  icon: 'OtherProbabilityHalf',
                  text: t('createQuiz.settings.appearing.half.title'),
                }}
                value={{
                  type: 'text',
                  text: t('createQuiz.settings.appearing.half.hint.content'),
                  sxProps: styles.tip(124),
                }}
              />
              <TooltipListItem
                name={{
                  icon: 'OtherProbabilityLikely',
                  text: t('createQuiz.settings.appearing.likely.title'),
                }}
                value={{
                  type: 'text',
                  text: t('createQuiz.settings.appearing.likely.hint.content'),
                  sxProps: styles.tip(124),
                }}
              />
            </TooltipList>
          }
          tooltipProps={{ followCursor: true }}
        >
          <SectionItem
            display={t('createQuiz.mock.appearing.title')}
            content={t('createQuiz.mock.appearing.content')}
            icon={<OtherProbabilityRareIcon width={19.2} height={19.2} />}
            disabled
          />
        </ResponsiveTooltip>
      </Box>
    </Section>
  );
}
