import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { userToChallengerItem } from '@app/web/src/components/CreateQuiz/components/panels/CreateQuizChallengersPanel/useCreateQuizChallengersDataset';
import { useBaseRightPanel } from '@front/ui';
import { apis, useMemberInfo } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { call } from '@lib/web/utils';

import { GlobalPanelKeys, GlobalPanelParams } from '../../types/panel';

import ProfileButton, { ProfileButtonProps } from './ProfileButton';

type SendButtonProps = {
  userId?: string;
  messageDisabled?: boolean;
} & Pick<ProfileButtonProps, 'size' | 'buttonType' | 'onClick' | 'onMouseDown'>;

export default function SendButton({
  userId,
  buttonType = 'emphasizeButton',
  messageDisabled = false,
  size,
  onClick,
  onMouseDown,
}: SendButtonProps) {
  const { t } = useTranslation();
  const clubSlug = useClubSlug();
  const [loading, setLoading] = useState(false);
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const isInClub = !!clubSlug;

  const { data: member } = useMemberInfo(!messageDisabled ? userId : null);
  const memberId = member?.data.memberId;
  const { views } = useDirectMessageViews();
  const { getThreadViewDetail } = useThreadViewDetails();

  const handlePrepareChallengeSource = useCallback(async () => {
    if (!userId) return [];
    const [res] = await call(apis.member.getSocialProfile(userId));
    const socialData = res?.data;

    if (!socialData) return [];

    return [
      userToChallengerItem({
        user: {
          userId: socialData.userId,
          userName: socialData.userName,
          displayName: socialData.fullName,
          nftAvatar: socialData.avatarUrl,
          avatar: socialData.photoUrl,
          indicator: socialData.indicator,
        },
        disabledUpdate: true,
      }),
    ];
  }, [userId]);

  const handleMessageClick = useCallback(() => {
    if (!memberId) return;
    setLoading(true);
    const existingView = views.find((view) => {
      const memberIds = getThreadViewDetail(view).viewMemberIds;
      return memberIds.length === 2 && memberIds.includes(memberId);
    });

    if (existingView) {
      void Router.push(`/direct-messages/view/${existingView.id}`);
    } else {
      void Router.push(`/direct-messages/view/${memberId}`);
    }
    setLoading(false);
  }, [getThreadViewDetail, memberId, views]);

  const handleChallengeClick = useCallback(async () => {
    setLoading(true);
    const challengerSource = await handlePrepareChallengeSource();
    setLoading(false);
    openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
      challengerSource,
    });
  }, [handlePrepareChallengeSource, openRightPanel]);

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      if (!isInClub) {
        handleMessageClick();
        return;
      }
      handleChallengeClick();
    },
    [isInClub, handleChallengeClick, handleMessageClick, onClick]
  );

  const dropdownOptions = useMemo(() => {
    if (!clubSlug || messageDisabled) return [];
    return [
      {
        display: 'Message',
        iconName: 'ThreadsDirectMessages',
        onClick: handleMessageClick,
      },
      {
        display: 'Challenge',
        iconName: 'MainChallenge',
        onClick: handleChallengeClick,
      },
    ];
  }, [clubSlug, messageDisabled, handleChallengeClick, handleMessageClick]);

  // requires userId
  // if is in a club => should not disable the message button
  const isValid = !!userId && (isInClub || (!isInClub && !messageDisabled));
  if (!isValid) return null;

  const label =
    dropdownOptions.length > 0
      ? t('button.Send')
      : isInClub
      ? t('button.Challenge')
      : t('button.Message');
  const icon =
    dropdownOptions.length > 0
      ? undefined
      : isInClub
      ? 'MainChallenge'
      : 'ThreadsDirectMessages';
  const variant =
    dropdownOptions.length > 0 || !isInClub ? 'outlined' : 'filled';

  return (
    <ProfileButton
      label={label}
      icon={icon}
      buttonType={buttonType}
      loading={loading}
      onClick={handleClick}
      onMouseDown={onMouseDown}
      dropdownOptions={dropdownOptions}
      variant={variant}
      size={size}
    />
  );
}
