import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Theme } from '@mui/material';
import { SquareAvatarStatus } from '@front/ui';
import {
  IconListLayoutItemActionMap,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import { ClubMemberRole } from '@lib/web/apis';
import { getUserStatus, useOnlineStatus } from '@lib/web/hooks';

type ItemRes = GetSearchClubMembersRes;

const getActionMap = (item: ItemRes): IconListLayoutItemActionMap => {
  if (item.isMe) {
    return {
      hover: {
        value: 'userHovered',
      },
    };
  }
  if (item.isFollowing) {
    return {
      hover: {
        value: 'userHovered',
      },
      click: {
        actionType: 'iconButton' as const,
        type: 'event' as const,
        value: 'challenge',
        icon: 'MainChallenge',
        customSize: 32,
      },
    };
  }

  return {
    hover: {
      value: 'userHovered',
    },
    click: {
      actionType: 'iconButton' as const,
      type: 'event' as const,
      value: 'follow',
      icon: 'OtherAddFriend',
      customSize: 32,
    },
  };
};

const getRoleLabel = (role: ClubMemberRole) => {
  if (role === ClubMemberRole.Admin) {
    return 'Admin';
  }

  if (role === ClubMemberRole.Editor) {
    return {
      text: 'Editor',
      color: 'yellow' as const,
    };
  }

  return undefined;
};

export function useClubMemberListLayoutItemMap() {
  const { t } = useTranslation();
  const { getUserOnlineStatus } = useOnlineStatus();
  const getStatus = useCallback(
    (id: string) => {
      const statusCode = getUserOnlineStatus(id);
      return getUserStatus(statusCode);
    },
    [getUserOnlineStatus]
  );

  return useCallback(
    (item: ItemRes): IconListLayoutItemObj<BaseMemberInfo> => {
      return {
        id: item.userId,
        title: item.displayName,
        titleSuffix: item.isMe ? 'Me' : '',
        titleSuffixSx: {
          typography: 'body1',
          ml: 1,
          px: 1,
          py: 0,
          lineHeight: '24px',
          borderRadius: 1,
          bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
          fontWeight: 400,
        },
        titleAction: {
          type: 'event' as const,
          value: 'titleClick',
        },
        label: getRoleLabel(item.role),
        description: `@${item.userName}`,
        avatarUrl: item.nftAvatar || item.avatar,
        hoverable: true,
        status: getStatus(item.userId),
        statusInfo: {
          [SquareAvatarStatus.Busy]: t('common::user.status.busy'),
        },
        actionMap: getActionMap(item),
        metadata: {
          userId: item.userId,
          avatar: item.avatar,
          displayName: item.displayName,
          distinctName: item.distinctName,
          nftAvatar: item.nftAvatar,
          indicator: item.indicator,
        },
      };
    },
    [getStatus, t]
  );
}

export default function useClubMembersTabDataset({
  dataset,
}: {
  dataset: ItemRes[];
}): IconListLayoutItemObj<BaseMemberInfo>[] {
  const toListLayoutItem = useClubMemberListLayoutItemMap();

  return useMemo(() => {
    return dataset.map(toListLayoutItem);
  }, [dataset, toListLayoutItem]);
}
