import { useIaSearchValues } from '@app/web/src/hooks/utils/useIaSearchValues';
import useIaAwardsAlmostThere from '@app/web/src/ia/award/useIaAwardAlmostThere';
import useIaAwardByCategory from '@app/web/src/ia/award/useIaAwardByCategory';
import { AwardBadgeCategory } from '@lib/web/apis';

export default function useProfileAwardsData({
  userId,
  limit,
}: {
  userId?: string;
  limit?: number;
}) {
  const { debouncedSearch: keyword, hasSearch } = useIaSearchValues();
  const {
    items: almostThereItems,
    totalCount: almostThereCount,
    isEmpty: isAlmostThereEmpty,
    isLoadingInitialData: isAlmostThereLoading,
  } = useIaAwardsAlmostThere({ limit, keyword });
  const {
    items: challengesItems,
    totalCount: challengesCount,
    isEmpty: isChallengesEmpty,
    isLoadingInitialData: isChallengesLoading,
  } = useIaAwardByCategory({
    category: AwardBadgeCategory.Challenges,
    limit,
    keyword,
    userId,
  });
  const {
    items: closeYourRingsItems,
    totalCount: closeYourRingsCount,
    isEmpty: isCloseYourRingsEmpty,
    isLoadingInitialData: isCloseYourRingsLoading,
  } = useIaAwardByCategory({
    category: AwardBadgeCategory.CloseYourRings,
    limit,
    keyword,
    userId,
  });
  const {
    items: limitedEditionItems,
    totalCount: limitedEditionCount,
    isEmpty: isLimitedEditionEmpty,
    isLoadingInitialData: isLimitedEditionLoading,
  } = useIaAwardByCategory({
    category: AwardBadgeCategory.LimitedEdition,
    limit,
    keyword,
    userId,
  });
  const {
    items: onFireItems,
    totalCount: onFireCount,
    isEmpty: isOnFireEmpty,
    isLoadingInitialData: isOnFireLoading,
  } = useIaAwardByCategory({
    category: AwardBadgeCategory.OnFire,
    limit,
    keyword,
    userId,
  });
  const {
    items: socialButterflyItems,
    totalCount: socialButterflyCount,
    isEmpty: isSocialButterflyEmpty,
    isLoadingInitialData: isSocialButterflyLoading,
  } = useIaAwardByCategory({
    category: AwardBadgeCategory.SocialButterfly,
    limit,
    keyword,
    userId,
  });

  const isEmpty =
    isAlmostThereEmpty &&
    isChallengesEmpty &&
    isCloseYourRingsEmpty &&
    isLimitedEditionEmpty &&
    isOnFireEmpty &&
    isSocialButterflyEmpty;

  const isLoading =
    isAlmostThereLoading ||
    isChallengesLoading ||
    isCloseYourRingsLoading ||
    isLimitedEditionLoading ||
    isOnFireLoading ||
    isSocialButterflyLoading;

  return {
    almostThereItems,
    almostThereCount,
    challengesItems,
    challengesCount,
    closeYourRingsItems,
    closeYourRingsCount,
    limitedEditionItems,
    limitedEditionCount,
    onFireItems,
    onFireCount,
    socialButterflyItems,
    socialButterflyCount,
    isEmpty,
    isLoading,
    hasSearch,
  };
}
