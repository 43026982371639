import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'next-i18next';
import { useClubSetting } from '@lib/web/apis';
import { useClubSlug, useCurrentIaClub } from '@lib/web/hooks';

import { CreateQuizFormValue } from '../../type';
export default function DurationDisplay() {
  const { t } = useTranslation('quiz');
  const clubSlug = useClubSlug();
  const { data: clubSetting } = useClubSetting(clubSlug);
  const { club } = useCurrentIaClub();
  const { watch } = useFormContext<CreateQuizFormValue>();
  const questionCount = +watch('questionCount', 2);

  if (!clubSetting || !club) return null;

  const questionTpq = clubSetting.data.timeMinutes / club.officialQuestionCount;
  const totalMinutes = questionTpq * questionCount;
  const minutes = Math.floor(totalMinutes);
  const seconds = Math.round((totalMinutes % 1) * 60);

  return (
    <Trans
      i18nKey="quiz::createQuiz.duration.hint.question"
      components={{ b: <b />, span: <span /> }}
      values={{
        count: questionCount,
        context:
          minutes === 0 ? 'onlySeconds' : seconds === 0 ? 'onlyMinutes' : '',
        minutes: t('quiz::createQuiz.duration.hint.minute', {
          count: minutes,
        }),
        seconds: t('quiz::createQuiz.duration.hint.second', {
          count: seconds,
        }),
      }}
    />
  );
}
