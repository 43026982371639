import { useMemo } from 'react';

export default function useQuizTopicRowItems({
  data,
  search,
  tab,
}: {
  data?: GetAnalyticsWeaknessTopicRes;
  search: string;
  tab: keyof GetAnalyticsWeaknessTopicRes;
}): GetAnalyticsWeaknessTopicItemRes[] {
  return useMemo(() => {
    const items = data?.[tab] || [];
    const trimSearch = search.trim().toLowerCase();

    return items.filter(
      (item) => !trimSearch || item.tagName.toLowerCase().includes(trimSearch)
    );
  }, [data, search, tab]);
}
