import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box, Chip, ChipProps, Typography } from '@mui/material';
import { excludeHashtag } from '@app/web/src/config/hasthag';
import {
  ActionClose as ActionCloseItem,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { ResponsiveTooltip, useBaseRightPanel } from '@front/ui';
import TooltipList from '@lib/ia/src/components/TooltipList';
import TooltipListItem from '@lib/ia/src/components/TooltipListItem';
import { TableLayoutActionsContext } from '@lib/ia/src/layouts/TableLayout/TableLayoutActions';
import { useAnalyticsWeaknessTopic } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { shortName } from '@lib/web/utils';
import { uniq } from 'lodash';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

import Section from './Section';

const styles = {
  root: {
    minWidth: 0,
    display: 'flex',
    gap: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    pt: 1,
  },
  more: {
    lineHeight: '40px',
    opacity: 0.5,
  },
  iconChip: {
    '& .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
};

const assignToTagFormat = (
  item: GetAnalyticsWeaknessTopicItemRes,
  option: {
    color: ChipProps['color'];
    type: string;
    icon: string;
  }
) => {
  const display = item.tagName;
  const shortDisplay = shortName(display, 28);
  return {
    ...option,
    display,
    shortDisplay,
    count: Math.round(item.questionRatioByType * item.questionCount),
    total: item.questionCount,
  };
};

export default function TopicCard() {
  const { t } = useTranslation('quiz');
  const { openRightPanel } = useBaseRightPanel();
  const [{ panelKeyPrefix, disabledParts }] = useContext(CreateQuizContext);
  const { watch, setValue } = useFormContext<CreateQuizFormValue>();
  const tags = watch('tags', []);
  const panelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.Topic}`;
  const disabled =
    disabledParts.includes('all') || disabledParts.includes('topics');
  const { sectionId } = useCurrentIaClub();
  const { data: originalData } = useAnalyticsWeaknessTopic(sectionId);
  const updatedData = useMemo(() => {
    if (!originalData) return null;

    return {
      mistake: originalData.data.mistake.filter(
        (d) => !excludeHashtag.includes(d.tagCode)
      ),
      overtime: originalData.data.overtime.filter(
        (d) => !excludeHashtag.includes(d.tagCode)
      ),
      notPracticedYet: originalData.data.notPracticedYet.filter(
        (d) => !excludeHashtag.includes(d.tagCode)
      ),
    };
  }, [originalData]);
  const { actionsRef: tableActionsRef } = useContext(TableLayoutActionsContext);
  const organizedTags = useMemo(() => {
    if (!updatedData) return [];
    const result: {
      display: string;
      shortDisplay: string;
      count: number;
      total: number;
      color: ChipProps['color'];
      type: string;
      icon: string;
    }[] = [];

    const selectedMistake = updatedData.mistake.filter((item) =>
      tags.includes(item.tagCode)
    );
    const selectedOvertime = updatedData.overtime.filter((item) =>
      tags.includes(item.tagCode)
    );
    const selectedNotPracticed = updatedData.notPracticedYet.filter((item) =>
      tags.includes(item.tagCode)
    );
    selectedMistake.forEach((item) => {
      result.push(
        assignToTagFormat(item, {
          color: 'error',
          type: 'mistake',
          icon: 'ActionCloseThick',
        })
      );
    });
    selectedOvertime.forEach((item) => {
      result.push(
        assignToTagFormat(item, {
          color: 'warning',
          type: 'overtime',
          icon: 'TestClockSolid',
        })
      );
    });
    selectedNotPracticed.forEach((item) => {
      result.push(
        assignToTagFormat(item, {
          color: 'primary',
          type: 'notPracticed',
          icon: 'TestOutOfPractice',
        })
      );
    });

    return result;
  }, [updatedData, tags]);

  const mistakeCodes = useMemo(() => {
    if (!updatedData) return [];
    return updatedData.mistake.map((item) => item.tagCode);
  }, [updatedData]);

  const selectAllMistake = () => {
    if (!updatedData) return;
    tableActionsRef.current?.toggleCheckbox(uniq(tags.concat(mistakeCodes)));
    setValue('tags', uniq(tags.concat(mistakeCodes)), { shouldDirty: true });
  };

  const removeAllMistake = () => {
    if (!updatedData) return;
    tableActionsRef.current?.toggleCheckbox(
      tags.filter((tagCode) => !mistakeCodes.includes(tagCode))
    );
    setValue(
      'tags',
      tags.filter((tagCode) => !mistakeCodes.includes(tagCode)),
      { shouldDirty: true }
    );
  };

  const restCount = organizedTags.length > 3 ? organizedTags.length - 3 : 0;
  const isSelectAllMistake =
    organizedTags.filter((tag) => tag.type === 'mistake').length ===
      updatedData?.mistake.length && updatedData?.mistake.length > 0;
  return (
    <Section title={t('createQuiz.topic.title', 'Topic')}>
      <Box sx={styles.root}>
        {isSelectAllMistake && (
          <Chip
            sx={styles.iconChip}
            variant="outlined"
            label={<ActionCloseItem width={16} height={16} />}
            onClick={removeAllMistake}
          />
        )}
        {!!updatedData?.mistake.length && (
          <ResponsiveTooltip
            title={t('createQuiz.topic.selectAll.title', 'Select All Mistakes')}
            content={t(
              'createQuiz.topic.selectAll.hint.content',
              'Select all topics where you’ve made mistakes to practice questions specifically tagged for focused practice.'
            )}
          >
            <Chip
              variant={isSelectAllMistake ? 'filled' : 'outlined'}
              label={t(
                'createQuiz.topic.selectAll.title',
                'Select All Mistakes'
              )}
              clickable
              disabled={disabled}
              onClick={selectAllMistake}
            />
          </ResponsiveTooltip>
        )}
        <ResponsiveTooltip
          titleIcon={<TestAddIcon width={16} height={16} />}
          title={t('createQuiz.topic.customize.hint.title', 'Customize Topics')}
          content={t(
            'createQuiz.topic.customize.hint.content',
            'Select topics to filter and practice questions specifically tagged for focused practice.'
          )}
        >
          <Chip
            variant="dashed"
            label={t('createQuiz.topic.customize.title')}
            icon={<TestAddIcon width={16} height={16} />}
            clickable
            disabled={disabled}
            onClick={() => openRightPanel(panelKey)}
          />
        </ResponsiveTooltip>
      </Box>
      {organizedTags.length > 0 && (
        <Box sx={styles.chips}>
          {organizedTags.slice(0, 3).map((tag) => (
            <ResponsiveTooltip
              key={`${tag.type} ${tag.display}`}
              title={`#${tag.shortDisplay}`}
              content={
                <TooltipList
                  content={t('createQuiz.topic.customize.hint.content', {
                    tag: `#${tag.shortDisplay}`,
                    context: tag.type,
                  })}
                >
                  {tag.count !== undefined && (
                    <TooltipListItem
                      name={{
                        icon: tag.icon,
                        text: t(
                          `createQuiz.topic.customize.property.${tag.type}`
                        ),
                      }}
                      value={{
                        type: 'text',
                        text: tag.count ? `${tag.count}` : '',
                      }}
                    />
                  )}
                  {tag.total !== undefined && (
                    <TooltipListItem
                      name={{
                        icon: 'ChatQuestionList',
                        text: t(
                          'createQuiz.topic.customize.property.totalQuestion',
                          'Total Questions'
                        ),
                      }}
                      value={{ type: 'text', text: `${tag.total}` }}
                    />
                  )}
                </TooltipList>
              }
            >
              <Chip
                variant="filled"
                color={tag.color}
                clickable
                label={
                  tag.count === undefined
                    ? `#${tag.display}`
                    : `#${tag.display}(${tag.count})`
                }
                onClick={() => openRightPanel(panelKey)}
                disabled={disabled}
              />
            </ResponsiveTooltip>
          ))}
          {restCount > 0 && (
            <Typography sx={styles.more} variant="body2">
              + {restCount} more
            </Typography>
          )}
        </Box>
      )}
    </Section>
  );
}
