import {
  AwardBadgeCategory,
  AwardBadgeViewSlug,
  useIaAwardBadgeList,
} from '@lib/web/apis';

import { enabledAwardSubTypes } from '../../config/awardConfig';
import useCurrentUserId from '../../hooks/utils/useCurrentUserId';

import useIaAwardBadgeData from './useIaAwardBadgeData';

export default function useIaAwardByCategory({
  userId,
  category,
  limit = 10,
  keyword,
}: {
  userId?: string;
  category: AwardBadgeCategory;
  limit?: number;
  keyword?: string;
}) {
  const currentUserId = useCurrentUserId();
  const data = useIaAwardBadgeList(
    {
      viewSlug: AwardBadgeViewSlug.ProfileCenterIaBadgeCategory,
      limit,
      filter: 'subType;goalType',
      search: `category:${category};subType:${enabledAwardSubTypes.join(',')}`,
      searchFields: `category:eq;subType:in`,
      keyword,
      userId,
    },
    {
      enable: !!currentUserId,
    }
  );
  const { dataset } = data;
  const items = useIaAwardBadgeData({
    items: dataset,
    userId,
  });

  return {
    ...data,
    items,
  };
}
