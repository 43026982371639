import { useEffect, useState } from 'react';
import WebThreadChat from '@app/web/src/components/WebThreadChat';
import { MESSAGE_SORT_FILTER_SCOPE } from '@app/web/src/config/thread';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import WebThreadChatToolbar from '@app/web/src/widgets/ThreadPage/WebThreadChatToolbar';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';

export default function ThreadChatPanel() {
  const { rightPanelOpened, getRightParams, rightPanelTarget } =
    useBaseRightPanel<GlobalPanelParams>();
  const { channelCid } = getRightParams(GlobalPanelKeys.GlobalThreadChat);
  const [channelCidState, setChannelCidState] = useState(channelCid);

  useEffect(() => {
    /**
     * for the case of open editor rhs (e.g. line marker), channelCid will be undefined, but we still need to keep original thread chat
     */
    if (!channelCid) return;
    setChannelCidState(channelCid);
  }, [channelCid]);

  return (
    <>
      <BaseLayoutRightPanel
        resizable
        toolComponent={
          <WebThreadChatToolbar
            channelCid={channelCidState}
            scope={MESSAGE_SORT_FILTER_SCOPE}
          />
        }
        inactive={rightPanelTarget !== GlobalPanelKeys.GlobalThreadChat}
      >
        {/* hide ThreadChat when rhs closed, so we can make WebThreadChat autofocus on composer by using useEffect(..., []) */}
        {rightPanelOpened && (
          <WebThreadChat
            channelCid={channelCidState}
            scope={MESSAGE_SORT_FILTER_SCOPE}
          />
        )}
      </BaseLayoutRightPanel>
    </>
  );
}
