import { useContext } from 'react';
import { PrivatePanelKeys } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';

import CreateQuizContext from '../context';

export default function useClosePanel() {
  const [{ variant, panelKeyPrefix }] = useContext(CreateQuizContext);
  const { openRightPanel } = useBaseRightPanel();
  return variant === 'page'
    ? undefined
    : () => openRightPanel(`${panelKeyPrefix}${PrivatePanelKeys.CreateQuiz}`);
}
