import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvitationNewUserItem } from '@app/web/src/hooks/utils/useAhaInvitation';
import { SearchState } from '@app/web/src/utils/search';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  IconListLayoutItem,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';

export default function useAddFriendsTabLayoutConfig({
  displayDataset,
  totalCount = 0,
  listState,
  newUser,
  selected,
  searchLoading,
  isSelected,
}: {
  displayDataset: IconListLayoutItemObj<BaseMemberInfo>[];
  totalCount: number;
  listState: SearchState | null;
  newUser: InvitationNewUserItem | null;
  selected: IconListLayoutItemObj[];
  searchLoading: boolean;
  isSelected: (id: string) => boolean;
}) {
  const { t } = useTranslation('club');

  return useMemo<IaLayoutConfig[]>(() => {
    const selectedItems: IconListLayoutItem[] =
      selected.length > 0
        ? [t('## Selected', { count: selected.length }), ...selected]
        : [];

    const filteredDataset = displayDataset.filter((d) => !isSelected(d.id));
    const filteredTotalCount = totalCount - selected.length;

    if (listState === SearchState.Initial) {
      if (displayDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items: selectedItems,
          },
        ];
      }

      const resultItems =
        filteredDataset.length > 0
          ? [
              t('## Following Not in Club', { count: filteredTotalCount }),
              ...filteredDataset,
            ]
          : [];

      return [
        {
          layout: 'icon-list-layout',
          items: [...selectedItems, ...resultItems],
        },
      ];
    }

    if (listState === SearchState.Searching) {
      if (!searchLoading && displayDataset.length === 0 && !newUser) {
        return [];
      }

      if (newUser) {
        return [
          {
            layout: 'icon-list-layout',
            items: [
              {
                id: newUser.email,
                title: newUser.email,
                avatarBlackAndWhite: true,
                actionMap: {
                  click: {
                    type: 'event',
                    value: 'inviteToAha',
                    text: t(
                      `club.RHS.addFriends.invite.aha.label.${newUser.actionText}`
                    ),
                    actionType: 'textButton',
                    disabled:
                      newUser.actionText === 'accepted' ||
                      newUser.actionText === 'pending',
                  },
                },
              },
            ],
          },
        ];
      }
    }

    const resultItems =
      filteredDataset.length > 0
        ? [t('## Results', { count: filteredTotalCount }), ...filteredDataset]
        : [];

    return [
      {
        layout: 'icon-list-layout',
        items: [...selectedItems, ...resultItems],
      },
    ];
  }, [
    selected,
    t,
    displayDataset,
    totalCount,
    listState,
    isSelected,
    searchLoading,
    newUser,
  ]);
}
