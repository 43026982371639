import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box, Theme } from '@mui/material';
import { testIdConfig } from '@front/config';
import {
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';
import { Button, TextButton, useBaseRightPanel } from '@front/ui';
import { useClubSlug, useIaClubStatus } from '@lib/web/hooks';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

const styles = {
  root: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    mt: 'auto',
    mx: '-12px',
    py: 2.5,
    px: '12px',
    background: (theme: Theme) => theme.palette.background.body,
    backgroundSize: '100vw 100vh',
    backgroundPosition: 'right bottom',
    backgroundAttachment: 'fixed',
    '& > .MuiButtonBase-root': {
      flex: 1,
    },
  },
  reset: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export default function BottomActions() {
  const { t } = useTranslation('quiz');
  const { reset, formState, watch } = useFormContext<CreateQuizFormValue>();
  const clubSlug = useClubSlug();
  const { comingSoon, inactive } = useIaClubStatus(clubSlug);
  const [{ panelKeyPrefix, availableQuestionCount, challengeMode }] =
    useContext(CreateQuizContext);
  const { openRightPanel } = useBaseRightPanel();

  const disabled = comingSoon || inactive || !availableQuestionCount;
  const challengerValue = watch('challengers');
  const isRandomChallengers = watch('isRandomChallengers');

  const hasChallengers = challengerValue.length > 0 || isRandomChallengers;
  return (
    <Box sx={styles.root}>
      <Box sx={styles.reset}>
        <TextButton
          onClick={() => reset(formState.defaultValues)}
          disabled={!formState.isDirty || formState.isSubmitting}
        >
          {t('createQuiz.buttons.reset', 'Set Default')}
        </TextButton>
      </Box>
      {challengeMode && !hasChallengers ? (
        <Button
          loading={formState.isSubmitting}
          disabled={!clubSlug || disabled}
          data-testid={testIdConfig.startPractice}
          onClick={() =>
            openRightPanel(`${panelKeyPrefix}${CreateQuizPanelKeys.Challenger}`)
          }
          prefixIcon={<OtherFriendsChallengeIcon />}
        >
          {t('createQuiz.buttons.addChallengers', 'Add Challengers')}
        </Button>
      ) : (
        <Button
          type="submit"
          loading={formState.isSubmitting}
          disabled={!clubSlug || disabled}
          data-testid={testIdConfig.startPractice}
          prefixIcon={<OtherPlayIcon />}
        >
          {hasChallengers
            ? t('createQuiz.buttons.startChallenge', 'Start Challenge')
            : t('createQuiz.buttons.start', 'Start Practice')}
        </Button>
      )}
    </Box>
  );
}
