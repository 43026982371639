import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { DisplayTableLayoutConfig } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  getClubIconPath,
  getLeagueBadgeIconName,
  getLeagueName,
} from '@lib/web/utils';

import DisplayTableChallengeButton, {
  DisplayTableChallengeButtonProps,
} from './DisplayTableChallengeButton';

const styles = {
  root: {
    width: '100%',
  },
};

export type ChallengeRecommendListProps = {
  body: GetChallengeGoodResultNotificationRes['data']['payload']['body'];
  cta: Extract<NotificationCtaType, { type: 'cta.challenge.recommend.list' }>;
};

export default function ChallengeRecommendList({
  body,
  cta,
}: ChallengeRecommendListProps) {
  const { t } = useTranslation('notification');
  const [collapse, setCollapse] = useState(false);

  const config = useMemo(() => {
    const fromClubId = body.clubId;
    const showClubColumn = cta.users.some((u) => u.clubId !== fromClubId);

    const gridTemplateColumns = showClubColumn
      ? '170px 119px 119px 34px 110px'
      : '170px 119px 34px 110px';

    const columnOrder = showClubColumn
      ? ['name', 'club', 'league', 'rank', 'action']
      : ['name', 'league', 'rank', 'action'];

    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns,
          columnOrder,
          headerRow: collapse
            ? null
            : {
                gridTemplateColumns,
                cells: [
                  {
                    type: 'default',
                    icon: 'MainProfileSolid',
                    label: t('challenge.good.result.table.name', 'Name'),
                  },
                  ...(showClubColumn
                    ? [
                        {
                          type: 'default',
                          icon: 'MainApps',
                          label: t('challenge.good.result.table.club', 'Club'),
                        },
                      ]
                    : []),
                  {
                    type: 'default',
                    icon: 'ProfileLeague',
                    label: t('challenge.good.result.table.league', 'League'),
                  },
                  {
                    type: 'default',
                    icon: 'TestTrophy',
                  },
                  {
                    type: 'default',
                    icon: 'PropertyTypeButtonCTA',
                    label: t('challenge.good.result.table.action', 'Action'),
                  },
                ],
              },
          rows: collapse
            ? []
            : cta.users.map((user) => {
                return {
                  id: user.userId,
                  cells: {
                    name: {
                      type: 'avatarText',
                      userId: user.userId,
                    },
                    club: {
                      type: 'avatarText',
                      avatar:
                        user.clubIcon || getClubIconPath(user.clubName || ''),
                      text: user.clubName,
                    },
                    league: {
                      type: 'iconText',
                      icon: {
                        type: 'icon',

                        value: getLeagueBadgeIconName(user.leagueTier),
                      },
                      text: getLeagueName(user.leagueTier),
                    },
                    rank: {
                      type: 'text',
                      text: `#${user.leagueRank}`,
                    },
                    action: {
                      type: 'custom',
                      renderKey: 'challengeButton',
                      metadata: {
                        userId: user.userId,
                      } as DisplayTableChallengeButtonProps,
                    },
                  },
                };
              }),
          footerRow: {
            gridTemplateColumns: '1fr',
            cells: [
              {
                type: 'button',
                action: 'toggleCollapse',
                text: collapse
                  ? t('challenge.good.result.table.show', 'Show table')
                  : t('challenge.good.result.table.hide', 'Hide'),
                icon: collapse ? 'ActionChevronDown' : 'ActionChevronUp',
              },
            ],
            rowHeight: 21,
          },
        },
        settings: {
          rowHeight: 32,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [body.clubId, collapse, cta.users, t]);

  const availableActions = useMemo(
    () => ({
      toggleCollapse: {
        action: () => {
          setCollapse((prev) => !prev);
        },
      },
    }),
    []
  );

  const renders = useMemo(() => {
    return {
      challengeButton: (ev: DisplayTableChallengeButtonProps) => (
        <DisplayTableChallengeButton {...ev} />
      ),
    };
  }, []);

  return (
    <Box sx={styles.root}>
      <IaRenderContextProvider value={renders}>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={config} />
        </IaActionContextProvider>
      </IaRenderContextProvider>
    </Box>
  );
}
