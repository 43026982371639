import { SxProps } from '@mui/material';
import { DisplayTableLayoutSelectOption } from '@lib/ia/src/layouts/DisplayTableLayout/types';
import { ComposerBlock } from '@lib/web/composer';
import { LiteralStringForUnion, UR } from 'stream-chat';

export enum AhaMessageType {
  Unknown = 0,
  DMNotificationMessage = 1,
}

export enum AgentComposingStatus {
  Thinking = 'thinking',
  Composing = 'composing',
  NotStarted = 'not-started',
  Completed = 'completed',
}

export enum AgentMaterialProcessStatus {
  File = 'reading-file',
  Image = 'reading-image',
  Paragraph = 'reading-paragraph',
}

export type StreamChatGenerics = {
  attachmentType: UR;
  channelType: UR & {
    /**
     * In channel layout, we will display multiple channels simultaneously.
     * The stream SDK only returns the latest messages along with channel data,
     * so we need to store the first message information ourselves.
     */
    firstMessageText?: string;
    firstMessageCustomBlocks?: ComposerBlock[];
    firstMessageCreatedAt?: string;
    firstMessageCreatedById?: string;
    firstMessageNotificationId?: string;
    firstMessageNotificationTag?: string;

    created_by?: { id: string };

    /**
     * Active members are those who have participated (sent messages) in this channel.
     */
    customActiveMembers?: string[];

    /**
     * The back-end will help us count the number of replies.
     */
    customReplyCount?: number;

    /**
     * These data are used for managing child channels. See the open-child-channel API for more details.
     */
    rootChannelCid?: string;
    parentChannelCid?: string;
    parentMessageId?: string;
    ancestorChannelCids?: string[];

    /**
     * Determines which agent should respond in this channel. The back-end will use this value to find the corresponding agent.
     */
    assignedAgentId?: string | null;

    /**
     * These values are used to set the location of the channel.
     */
    clubId?: string;
    location?: string;
    locationQuery?: string;
    locationOwnerId?: string;
    questionId?: string;
    shortId?: string;
    quizId?: string;
    questionNo?: string;

    /**
     * The 'tag' property is currently utilized to differentiate between a 'solution' channel and a regular channel.
     */
    tag?: 'solution';

    /**
     * We have multiple types of thumbnails to display.
     */
    selectedHeadThumbnailOption?: string;
    selectedHeadThumbnailSubOption?: string;
    headThumbnailOptions?: string;
  };
  commandType: LiteralStringForUnion;
  eventType: UR;
  messageType: UR & {
    /**
     * 'text' is the default field in the Stream SDK, indicating the message content.
     */
    text?: string;

    /**
     * Since our composer supports rich text, we use custom block formats to render it.
     */
    customBlocks?: ComposerBlock[];

    /**
     * 'customId' is used for better UX while the message is sending before it succeeds.
     */
    customId?: string;

    created_at?: string;

    /**
     * These data are used for managing child channels. See the open-child-channel API for more details.
     */
    parentMessageCreatedAt?: string;
    rootMessageId?: string;
    parentMessageId?: string;

    /**
     * Sometimes (e.g., open-child-thread) we don't want the agent to respond when sending a message.
     */
    customAgentResponseControl?: 'no-reply';

    /**
     * Used to render system messages. In some cases, we also need to store the related user (customUserId) for this event (e.g., who triggered this action).
     */
    customEventType?: 'memberInvited' | 'memberRemoved' | 'agentStatusError';
    customUserId?: string;
    customInvitedMemberIds?: string[];
    customCtaType?: 'upgrade';
    customCtaStatus?: 'pending' | 'completed';
    customAgentId?: string | null;

    /**
     * Used to render notification messages
     */
    customAhaMsgType?: AhaMessageType;
    customNotificationId?: string;
    customNotificationTag?: string;

    /**
     * Used to check agent message status
     */
    agentComposingStatus?: AgentComposingStatus;
    agentMaterialProcessStatus?: AgentMaterialProcessStatus;
    agentMaterialFileName?: string;

    /**
     * Used to determine whether the message displays pagination
     */
    previousText?: string[] | null;
  };
  pollOptionType: UR;
  pollType: UR;
  reactionType: UR;
  userType: UR;
};

export type LocationDetail = {
  location?: string;
  query?: Record<string, string | string[] | undefined>;
  locationOwnerId?: string;
  questionId?: string;
  shortId?: string;
  quizId?: string;
  clubId?: string;
  questionNo?: number;
};

export type HeadThumbnailDetail = {
  selectedOption: string;
  selectedSubOption?: string;
  options: DisplayTableLayoutSelectOption[];
};

export type HumanThreadUser = {
  type: 'user';
  id: string;
  name?: string;
  image?: string;
  distinctName?: string;
  memberId?: string;
  userId?: string;
  indicators?: { icon: string; name: string }[];
};

export type AgentThreadUser = {
  type: 'agent';
  id: string;
  name?: string;
  distinctName?: string;
  image?: string;
  agentId?: string;
  agentClub?: string;
  indicators?: { icon: string; name: string; sx?: SxProps }[];
};

export type ThreadUser = HumanThreadUser | AgentThreadUser;

export type ThreadUserGetter = (threadMemberId?: string) => ThreadUser | null;

export type ThreadLocationDisplay = {
  name: string;
} & (
  | {
      type: 'icon';
      icon: string;
    }
  | {
      type: 'avatar';
      avatarUrl: string;
    }
);

export type ThreadLocationDisplayGetter = (
  locationDetail?: LocationDetail
) => ThreadLocationDisplay | null;

export type ThreadNotificationMessagePayloadGetter = (
  notificationId?: string
) => GetThreadMessageNotificationPayloadRes | null | undefined;

export type AgentMaterial = {
  type: 'text';
  value: string;
};
