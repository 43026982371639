import { PropsWithChildren, ReactNode, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AutoJoinClubButton from '@app/web/src/components/AutoJoinClubButton';
import usePlaylistInfo from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistInfo';
import {
  OtherPlay as OtherPlayIcon,
  PropertyTypeDateOrTime as PropertyTypeDateOrTimeIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanelPortal,
  EmphasizeButton,
  useBaseLayout,
} from '@front/ui';
import { ClubJoinedStatus, ClubStatus, useAuth } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { clearRouteParams } from '@lib/web/utils';

import useAuthLinks from '../../hooks/utils/useAuthLinks';
import { PrivatePanelKeys } from '../../types/panel';
import AuthGuard from '../AuthGuard';

import CreateQuizByPanel, { CreateQuizByPanelProps } from './CreateQuizByPanel';

function CreateQuizPanelButton({
  panelKeyPrefix = '',
  children,
  loading,
  prefixIcon,
  hasPanel,
}: PropsWithChildren<{
  loading?: boolean;
  panelKeyPrefix?: string;
  prefixIcon?: ReactNode;
  hasPanel?: boolean;
}>) {
  const { t } = useTranslation('quiz');
  const { type } = usePlaylistInfo();
  const { openRightPanel } = useBaseLayout();
  const { isLogged } = useAuth();
  const router = useRouter();
  const { club } = useCurrentIaClub();
  const { loginUrl } = useAuthLinks({
    loginReturnTo: window?.location?.href,
    loginQueries: ['started=true'],
  });
  const isJoined = club?.joinStatus === ClubJoinedStatus.Joined;
  const isRequestedPending =
    club?.joinStatus === ClubJoinedStatus.RequestedPending;
  const isInvitedPending =
    club?.joinStatus === ClubJoinedStatus.ManagerInvitedPending;

  const openQuizPanel = useCallback(() => {
    openRightPanel(
      `${panelKeyPrefix}${PrivatePanelKeys.CreateQuiz}`,
      (params) => params
    );
  }, [openRightPanel, panelKeyPrefix]);

  const onJoinedClubSuccess = () => {
    openQuizPanel();
  };

  useEffect(() => {
    const shouldOpenPanelDirectly = router.query.started === 'true' && hasPanel;

    if (shouldOpenPanelDirectly && isJoined) {
      openQuizPanel();

      clearRouteParams(['started']);
    }
  }, [isJoined, router.query.started, openQuizPanel, hasPanel]);

  if (!club) return null;

  if (!isLogged) {
    return (
      <EmphasizeButton
        prefixIcon={prefixIcon || <OtherPlayIcon />}
        component={Link}
        href={loginUrl}
        loading={loading}
      >
        {children || t('button.Start')}
      </EmphasizeButton>
    );
  }

  if (isJoined) {
    const disabled =
      (type !== 'creators' && club.clubStatus === ClubStatus.ComingSoon) ||
      club.clubStatus === ClubStatus.Inactive;

    return (
      <AuthGuard>
        <EmphasizeButton
          prefixIcon={prefixIcon || <OtherPlayIcon />}
          disabled={disabled}
          onClick={openQuizPanel}
          loading={loading}
        >
          {children || t('button.Start')}
        </EmphasizeButton>
      </AuthGuard>
    );
  }

  if (isRequestedPending) {
    return (
      <EmphasizeButton prefixIcon={<PropertyTypeDateOrTimeIcon />} disabled>
        Pending Approval
      </EmphasizeButton>
    );
  }

  if (isInvitedPending) {
    return (
      <EmphasizeButton prefixIcon={<TextEditorCheckIcon />}>
        Accept Invite
      </EmphasizeButton>
    );
  }

  return (
    <AutoJoinClubButton
      prefixIcon={prefixIcon || <OtherPlayIcon />}
      joinOnLoad={router.query.started === 'true'}
      onSuccess={onJoinedClubSuccess}
      loading={loading}
    >
      {children || t('button.Start')}
    </AutoJoinClubButton>
  );
}

export type CreateQuizPanelButtonProps = {
  withPanel?: boolean;
  loading?: boolean;
  prefixIcon?: ReactNode;
} & CreateQuizByPanelProps;

export default function CreateQuizPanelButtonRoot({
  withPanel = false,
  loading = false,
  prefixIcon,
  children,
  ...rest
}: CreateQuizPanelButtonProps) {
  return (
    <>
      <CreateQuizPanelButton
        panelKeyPrefix={rest.panelKeyPrefix}
        prefixIcon={prefixIcon}
        loading={loading}
        hasPanel={withPanel && !loading}
      >
        {children}
      </CreateQuizPanelButton>
      {withPanel && !loading && (
        <BaseLayoutRightPanelPortal>
          <CreateQuizByPanel {...rest} />
        </BaseLayoutRightPanelPortal>
      )}
    </>
  );
}
